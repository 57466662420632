//// Login

//로그인 사용자 아이디
export const setUserName = (infoUserName) => ({
  type: 'INFO_USER_NAME',
  payload: infoUserName
});

//// 테스트 이용률

// //1. 통계 - 챗봇 데이터 이용률
// export const setChatDataCounts = (chatDataCount) => ({
//   type: 'SET_CHAT_DATA_COUNTS',
//   payload: chatDataCount
// })

// //1. 통계 - 헬스 데이터 이용률
// export const setHealthDataCounts = (healthDataCount) => ({
//   type: 'SET_HEALTH_DATA_COUNTS',
//   payload: healthDataCount
// })

// //2. 챗봇 - 챗봇 이용률
// export const setChatCounts = (chatCount) => ({
//   type: 'SET_CHAT_COUNTS',
//   payload: chatCount
// });

// //2. 챗봇 - AI챗봇 이용률
// export const setAIChatCounts = (AIChatCount) => ({
//   type: 'SET_AI_CHAT_COUNTS',
//   payload: AIChatCount
// })

// //3. 이미지 - OCR 이용률
// export const setOCRCounts = (OCRCount) => ({
//   type: 'SET_OCR_COUNTS',
//   payload: OCRCount
// })

// //4. NLP - Her-a 이용률
// export const setHeraCount = (HeraCount) => ({
//   type: 'SET_HERA_COUNTS',
//   payload: HeraCount
// })

// 데이터 대시보드 -Input Dates
export const setLookUpChatbotDatas = (startDate, endDate) => ({
  type: 'SET_LOOK_UP_CHATBOT_DATAS',
  payload: {
    startDate,
    endDate
  }
});

// 대시보드 Weekly User Count
export const setWeekUserCount = (inputCounts) => ({
  type: 'SET_WEEK_USER_COUNT',
  payload: inputCounts
});

//// 대시보드 - 헬스 데이터
export const setLookUpHealthDatas = (startHealthDate, endHealthDate) => ({
  type: 'SET_LOOK_UP_HEALTH_DATAS',
  payload: {
    startHealthDate,
    endHealthDate
  }
})

//// AI 

//AI 챗봇 비밀번호
export const setPassword = (password) => ({
  type: 'SET_PASSWORD',
  payload: password
});

//챗봇 생성 상태값
export const setIsChatBotCreating = (isCreating) => ({
    type: 'SET_IS_CHATBOT_CREATING',
    payload: isCreating
  });

//챗봇의 응답 데이터 chain_num
export const setChainNum = (chainNum) => ({
    type: 'SET_CHAIN_NUM',
    payload: chainNum
  });

//챗봇의 사용자 질문
export const setUserQuestion = (question) => ({
    type: 'SET_USER_QUESTION',
    payload: question
});


//챗봇의 사용자 질문에 대한 답변
export const setChatAnswer = (answer) => ({
    type: 'SET_Chat_ANSWER',
    payload: answer
  });

//// OCR

//사용자 insert 이미지
export const setInsertImage = (userImage) => ({
  type: 'SET_INSERT_IMAGES',
  payload: userImage
});

// 이미지 생성 상태값
export const setIsImageCreating = (ImageCreating) => ({
  type: 'SET_IS_IMAGE_CREATING',
  payload: ImageCreating
});

//// Her-a

//Her-a 생성 상태값
export const setIsTextSummaryCreating = (TextIsCreating) => ({
  type: 'SET_IS_TEXTSUMMARY_CREATING',
  payload: TextIsCreating
});

//Her-a 검사명 (뇌/유방/갑상선 등)
export const setExamClass = (ExamClass) => ({
  type: 'SET_EXAM_CLASS',
  payload: ExamClass
});

//Her-a 결과 값 (정상/유소견/미분류)
export const setTextSummaryResult = (TextSummaryResult) => ({
  type: 'SET_TEXT_SUMMARY_RESULT',
  payload: TextSummaryResult
});

// 테크 게시판 개수
export const setTechPostLength = (PostLenght) => ({
  type: 'SET_TECH_POST_LENGTH',
  payload: PostLenght
});

// 홈 통계 - ChatData
export const setChatDatas = (chatDatas) => ({
  type: 'SET_CHAT_DATAS',
  payload: chatDatas
})










  
