import TechIndex from "../../../Techindex";
import React from "react";
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// import components
import { InputPDF } from "./components/inputPDF";
import ResultOCR from "./components/resultOCR";

import { TitleItem } from "componets/Item";

// import material UI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const OCRmain = () => {
    const ImageCreating = useSelector(state => state.ImageCreating);

    return (
        <Layout.Content>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={15}>
                        <TitleItem>
                            {/* 타이틀 */}
                            <div className="title-area">
                                <div className="main-title">
                                    OCR 테스트 화면
                                </div>
                                <p></p>
                                <div className="sub-title">
                                    이미지를 텍스트로 변환하는 기술인 OCR의 테스트 화면입니다.
                                </div>
                            </div>
                        </TitleItem>
                    </Grid>
                    <Grid item xs={20}>
                        <TitleItem  >
                            {/* 기술영역 */}
                            <div className="tech-area" style={{ display: 'grid', height:'1000px' }}>
                                <div style={{ marginTop: '10px', padding: '15px', backgroundColor: '#eff1f6', borderRadius: '2%' }}>
                                    < InputPDF/>
                                </div>
                            </div>
                        </TitleItem>
                    </Grid>
                </Grid>
            </Box>
        </Layout.Content>
    );
};

const OCR = () => {
    return (
        <TechIndex>
            <OCRmain />
        </TechIndex>
    );
};

export default OCR;
