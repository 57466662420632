import { React, useState, useEffect } from 'react'

// import antd
import Dragger from 'antd/es/upload/Dragger'
import { Layout, Radio, Input, Alert } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { TitleItem } from 'componets/Item';

// import material-ui
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

// import apies
import { postPDF } from '../../../../../apies/status/fetcher';
import { postChat } from '../../../../../apies/status/fetcher';

// redux 
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setIsChatBotCreating } from '../../../../../action/action';
import { setChainNum } from '../../../../../action/action';
import { withSuccess } from 'antd/es/modal/confirm';

let uploadedFile = null;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const defaultPrompt = 'your infomation\n' +
  '[name : 바름이\n' +
  'age : 30\n' +
  'MBTI : ENTJ\n' +
  'sex : femaln' +
  'address : 서울시 강남구 도산대로 22n' +
  'job : 자세전문n' +
  'hobby : 모든 운동(특히 스트레칭, 필라테스, 요가), 독서, 자료검색]\n' +

  '당신은 주어진 질문에 대한 답변을 친절하게 해야 합니다.\n' +
  '답변은 반드시 한국어 이여야하며, 짧고 간결하게 구성되어야 합니다.';


export const InputFileAndPrompt = () => {

  const passwordMatches = useSelector(state => state.password);

  // 라디오 체크 확인
  const [value, setValue] = useState(1);

  const isCollectPassword = localStorage.getItem('AIchatbotPW');

  // 디폴트 == 1 
  useEffect(() => {
    if (value === 1) {
      setInputPrompt(defaultPrompt);
      console.log('샘플선택', defaultPrompt);
      console.log(isCollectPassword)
    }
  }, [value, defaultPrompt, isCollectPassword]);

  //프롬프트 입력 여부 확인
  const [inputprompt, setInputPrompt] = useState('');

  //업로드 파일 여부 확인
  const [isUploadFile, setIsUploadFile] = useState(null);

  const handleFileChange = (event) => {
    uploadedFile = event.target.files[0]
    console.log('첨부된 파일:', uploadedFile);
    console.log('첨부된 이름:', uploadedFile.name);


    if (uploadedFile) {
      setIsUploadFile('success');
    } else if (!uploadedFile) {
      setIsUploadFile(null);
    } else {
      setIsUploadFile('fail');
    }
  };

  const { TextArea } = Input;

  const onCheck = (e) => {
    console.log('radio checked', e.target.value);
    setValue(parseInt(e.target.value));

    if (parseInt(e.target.value) === 1) {
      // 샘플 선택
      setInputPrompt(defaultPrompt);
      console.log('샘플선택', defaultPrompt);
    } else if (parseInt(e.target.value) === 2) {
      // 수동 입력
      setInputPrompt('');
    }
  };

  //리듀스 변수 컴포넌트 내에 정의
  const isChatBotCreating = useSelector(state => state.isChatBotCreating);
  const dispatch = useDispatch();

  // dispatch 사용 시 아래 한줄때문에 에러 발생
  // const [chainNum, setChainNum] = useState('');

  const handlePromptChange = (e) => {
    setInputPrompt(e.target.value);
  };

  const startSubmit = () => {

    // AIchatbot 테스트 이용률 집계
    let AiChatCount = localStorage.getItem('AIChatCount');

    if (AiChatCount === null || AiChatCount === "null") {
      AiChatCount = 0;
    } else {
      AiChatCount = parseInt(AiChatCount);
    }

    AiChatCount += 1;
    localStorage.setItem('AIChatCount', JSON.stringify(AiChatCount));

    // AIchatbot 일일 이용횟수 집계
    const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD

    const dailyStoredData = localStorage.getItem(`AIChatCounts-${today}`);
    const dailyDataCount = parseInt(dailyStoredData, 10) || 0;

    const dailyCounts = dailyDataCount + 1;

    // const dailyChatCount = {
    //   count: dailyCounts,
    //   timeStamp: new Date().toISOString()
    // };

    localStorage.setItem(`AIChatCounts-${today}`, dailyCounts);


    console.log('확인', uploadedFile)
    if (!uploadedFile) {
      console.error('파일이 업로드되지 않았습니다.');
      uploadedFile = 'empty'
    }
    console.log('파일 업로드');

    let formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('prompt', inputprompt);

    console.log('파일확인', uploadedFile);
    console.log('프롬프트확인', inputprompt);

    postPDF(formData)
      .then(response => {
        console.log('파일 업로드 완료', response.data);
        let ResponseData = new FormData();
        ResponseData.append('prompt', inputprompt)
        // 업로드 한 RAG 파일이 없을 경우, 빈 내용의 RAG file path 지정
        if (uploadedFile === 'empty') {
          ResponseData.append('file_path', 'rdl-GPT-chatbot/pdf/goHealth.pdf')
        }
        else {
          ResponseData.append('file_path', response.data[0])
        }

        //챗봇 생성 중으로 상태 변경
        dispatch(setIsChatBotCreating('create'));

        // 파일 업로드 이후 챗봇 생성 요청 전달
        postChat(ResponseData)
          .then(response => {
            console.log('챗봇 생성요청 성공', response.data['chain_num']);
            const chainNum = response.data['chain_num']

            // reudx 상태 업데이트
            dispatch(setChainNum(chainNum))
            dispatch(setIsChatBotCreating(true));
          })
          .catch(error => {
            console.log('챗봇 생성 요청 실패', error);
          });
      })
      .catch(error => {
        console.error('파일 업로드 실패', error);
        // return error;
      });
  }
  return (
    <>
      <div style={{ textAlign: 'left' }}>
        <h3 style={{ marginBottom: '3px' }}>• 프롬프트 입력
          <span style={{ marginLeft: '3px' }}>
            <Tooltip title="프롬프트란 사용자가 GPT에게 어떤 종류의 작업을 수행하도록 지시하는 문장 또는 단어를 말합니다. 아래 샘플을 선택하거나 직접 입력으로 챗봇을 만들어보세요!" placement="right">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </h3>
        <Radio.Group onChange={onCheck} value={value}>
          <Radio value={1}>
            <h3 style={{ fontSize: '14px' }}>샘플</h3>
          </Radio>
        </Radio.Group>
        <Radio.Group onChange={onCheck} value={value}>
          <Radio value={2}>
            <h3 style={{ fontSize: '14px' }}>직접 입력</h3>
          </Radio>
        </Radio.Group>
        {value === 1 && (
          <div style={{ backgroundColor: 'white', borderRadius: '3%', padding: '7px' }}>
            your infomation
            <p></p>
            [name : 바름이<br />
            age : 30<br />
            MBTI : ENTJ<br />
            sex : Female<br />
            address : 서울시 강남구 도산대로 22<br />
            job : 자세전문<br />
            hobby : 모든 운동(특히 스트레칭, 필라테스, 요가), 독서, 자료검색]<br />
            당신은 사용자에게 올바론 건강 정보를 전달하는 AI 어시스턴트입니다.<br />
            당신의 이름은 바름이이며, 항상 바른 자세를 취하자는 의미에서 지은 이름입니다.<br />
            당신은 주어진 질문에 대한 답변을 친절하게 해야 합니다.<br />
            답변은 반드시 한국어 이여야하며, 짧고 간결하게 구성되어야 합니다.<br />
            <p></p>
          </div>
        )}
        {value === 2 && (
          <TextArea
            value={inputprompt}
            onChange={handlePromptChange}
            rows={4} placeholder="GPT에 명령하고 싶은 프롬프트를 입력해주세요." style={{ height: 'center' }} />
        )}
      </div>
      <div style={{ textAlign: 'left' }}>
        <h3>• RAG PDF첨부</h3>
        <TitleItem>
          <div style={{ textAlign: 'left', fontFamily: "'Noto Sans KR', sans-serif" }}>챗봇이 한정된 스크립트 내에서 대답하길 원한다면 스크립트 PDF를 첨부해주세요.</div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left', fontFamily: "'Noto Sans KR', sans-serif" }}>
            <div>
              PDF파일을 첨부하지 않을 경우에도 챗봇 생성이 가능합니다.
            </div>
            <div>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                파일 첨부
                <VisuallyHiddenInput type="file" onChange={handleFileChange} style={{ fontFamily: '"Noto Sans KR", sans-serif' }} />
              </Button>
            </div>
          </div>
        </TitleItem>
        <p></p>
        {/* <Button onClick={startSubmit} type="primary">테스트 시작하기</Button> */}
        <p></p>
        {isUploadFile === 'success' && (
          <Alert style={{ fontFamily: '"Noto Sans KR", sans-serif' }} message="파일이 첨부되었습니다." type="success" />
        )}
        {isUploadFile === 'fail' && (
          <Alert style={{ fontFamily: '"Noto Sans KR", sans-serif' }} message="파일 첨부에 실패했습니다." type="error" />
        )}
        <p></p>
        <div style={{ textAlign: 'right', margin: 'auto' }}>
          <Button
            variant="outlined"
            onClick={startSubmit}
            style={{ fontFamily: '"Noto Sans KR", sans-serif' }}
            disabled={passwordMatches !== '1234'}
          >
            테스트 시작하기
          </Button>
        </div>

      </div><div>

        {/* <p></p> */}
      </div>
      <div></div>


    </>


  )
};
