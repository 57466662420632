import { React, useState, useEffect } from 'react'
import { Input } from 'antd'

// redux 
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setPassword } from 'action/action';

export const Checkpassword = () => {
  const [Chatpassword, setChatPassword] = useState('');
  const [error, setError] = useState('');

  const passwordMatches = useSelector(state => state.password);
  const dispatch = useDispatch();

  useEffect(() => {
  }, [passwordMatches]);

  const handleChatpasswordChange = (e) => {
    setChatPassword(e.target.value);

    if (e.target.value === 'dlgodls1') {
      dispatch(setPassword(e.target.value));
      setError('');
    } else {
      dispatch(setPassword(e.target.value));
      setError('비밀번호 불일치');
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <h3>• 비밀번호 입력</h3>
      <div>AI 챗봇은 유료 서비스입니다. 관리자에게 부여 받은 비밀번호 입력을 해주세요.</div>
      <p></p>
      <Input.Password
        placeholder='비밀번호를 입력하세요.'
        type='text'
        value={Chatpassword}
        onChange={handleChatpasswordChange}
      ></Input.Password>
      <p></p>
      {error && <div className='default-errorMessage'>*비밀번호를 확인해주세요.</div>}
      <p></p>
    </div>
  )
}
