import TechIndex from '../../../Techindex';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'

// import material UI
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { TitleItem } from 'componets/Item';

// import antd--
import { Layout } from 'antd';
import { Radio, Input, Modal, Space } from 'antd';
import { CloseOutlined,ExclamationCircleFilled } from '@ant-design/icons';

// import react-draft-wysiwyg  - 텍스트 에디터
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import apies
import { ShowEditTrendPost } from 'apies/status/postFetcher';
import { editTrendPost } from 'apies/status/postFetcher';

const { Content } = Layout;
const { confirm } = Modal;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const MyBlock = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 1rem; /* 각각의 요소들 사이의 간격 설정 */
    
    .editor {
        height: 330px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }

    .rdw-option-wrapper {
        display: flex; 
        gap: 0.5rem; 
    }
`;

export const TechTrendSubEdit = () => {

    const { id } = useParams();

    const [index, setIndex] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');
    const [user, setUser] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        ShowEditTrendPost(id)
            .then(response => {
                const postsData = response.data;

                setIndex(response.data['id']);
                setTitle(response.data['title']);
                setContent(response.data['content']);
                setStatus(response.data['status']);
                setDate(response.data['date']);
                setUser(response.data['user']);
                setFile(response.data['filePath']);

                const blocksFromHTML = convertFromHTML(content);
                const state = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                setEditorState(EditorState.createWithContent(state));

            })
            .catch(error => {
                console.error('데이터없음', error);
                Modal.error({
                    title: '알 수 없는 에러입니다.',
                    onOk() {
                        console.log('OK');
                        window.location.href = '/techboard' + id
                    },
                });
            });

    }, [id, title, content, file, status]);

    // 타이틀 
    const [titleValue, setTitleValue] = useState('');

    useEffect(() => {
        setTitleValue(title);
    }, [title]);

    const handleInputChange = (e) => {
        setTitleValue(e.target.value);
    };

    const [statusValue, setStatusValue] = useState(1);
    const onStatusChange = (e) => {
        console.log('radio checked', e.target.value);
        setStatusValue(e.target.value);
    };

    const [editorState, setEditorState] = useState(() => {
        console.log(content)
        const contents = ContentState.createFromText(
            "DEFAULT"
        );
        return EditorState.createWithContent(contents);
    });

    // 에디터 내용
    const [ContentValue, setContentValue] = useState('');

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        setContentValue(plainText);
    };

    // 파일이 삭제되었는지 확인
    const [isFileDeleted, setIsFileDeleted] = useState(false);

    const onDeleteFile = () => {
        setFile(null);
        setIsFileDeleted(true);
    }

    const onClickEvent = () => {
        if (ContentValue) {
            confirm({
                title: '작성중인 내용이 있습니다.',
                icon: <ExclamationCircleFilled />,
                content: '정말 다른 메뉴로 이동하시겠습니까?',
                okText: '계속 작성하기',
                okType: 'primary',
                cancelText: '목록으로 돌아가기',
                onOk() {
                    console.log('OK');
                },
                onCancel() {
                    window.location.href = '/techBoard'
                },
            });
        }
        else {
            window.location.href = '/techBoard';
        }
    }

    const onEditSucess = () => {

        let formData = new FormData();

        if (titleValue !== '' && ContentValue !== '') {

            // 수정 날짜로 변경 
            const today = new Date();
            
            formData.append('title', titleValue)
            formData.append('user', user)
            formData.append('date', today.toISOString().split('T')[0])
            formData.append('id', id)
            formData.append('status', statusValue)
            formData.append('content', ContentValue)
            formData.append('file', file)

            console.log(title, user, date, id, status, content)

            editTrendPost(formData)
                .then(response => {
                    Modal.success({
                        content: '게시글 수정이 완료되었습니다.',

                        onOk() {
                          console.log('OK');
                          window.location.href='/techboard'
                        },

                      })
                })
        }

        else {
            Modal.warning({
                title: '경고',
                content: '제목과 내용을 입력해주세요.',
            });
        }
    }

    return (
        <Content>
            <TitleItem>
                <div className="title-area">
                    <div className="main-title">
                        기술 트렌드
                    </div>
                    <p></p>
                    <div className="sub-title">
                        기술 동향 게시판 입니다.
                    </div>
                </div>
            </TitleItem>
            <div className="tech-area" style={{ display: 'grid', fontSize: '12px', width: '100%' }}>
                <div>
                    <TitleItem style={{ marginTop: '15px' }}>
                        <div className='search-area' style={{ display: 'grid', width: '100%', gridTemplateColumns: '20% 80%' }}>
                            <div className='search-menu'>제목</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <div style={{ overflow: 'auto', backgroundColor: 'white' }}>
                                    <Input
                                        value={titleValue}
                                        onChange={handleInputChange}
                                        placeholder="제목을 입력하세요."
                                    />
                                </div>
                            </div>
                            <div className='search-menu'>작성자</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                {user}
                            </div>
                            <div className='search-menu'>공개여부</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <Radio.Group onChange={onStatusChange} defaultValue={status} value={statusValue}>
                                    <Radio value={1}>공개</Radio>
                                    <Radio value={2}>비공개</Radio>
                                </Radio.Group>
                            </div>
                            <div className='search-menu' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >내용</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <MyBlock>
                                    <Editor
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor"
                                        toolbarClassName="toolbar-class"
                                        toolbar={{
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: false },
                                        }}
                                        placeholder="내용을 작성해주세요."
                                        localization={{ locale: 'ko' }}
                                        editorState={editorState}
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </MyBlock>
                            </div>
                            <div className='search-menu'>첨부 파일</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <div>
                                    {file && !isFileDeleted && (
                                        <div style={{ fontWeight: 'bold' }}>
                                            {file}
                                            <span onClick={onDeleteFile}>
                                                <Space>
                                                    <CloseOutlined style={{ fontSize: '12px', color: '#cc2900', height: '2em' }} />
                                                </Space>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className='search-menu'>파일 업로드</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <div>
                                    추가업로드파일~
                                </div>
                            </div> */}
                        </div>
                        <div style={{ textAlign: 'right', margin: 'auto', marginTop: '1%' }}>
                            <Button variant="outlined" onClick={onClickEvent} style={{ fontFamily: '"Noto Sans KR", sans-serif', marginRight: '2%' }}>목록으로 이동</Button>
                            <Button variant="contained" onClick={onEditSucess} style={{ fontFamily: '"Noto Sans KR", sans-serif' }}>등록</Button>
                        </div>
                    </TitleItem>
                </div>
            </div >
        </Content >
    )
}

const TechTrendEdit = () => {
    return (
        <TechIndex>
            <TechTrendSubEdit />
        </TechIndex>
    );
};

export default TechTrendEdit;
