import TechIndex from "../../../../Techindex";
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import antd
import { Button, Layout, DatePicker as AntDatePicker, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// import material UI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// import iconds
import { FcCalendar } from "react-icons/fc";
import { FcConferenceCall } from "react-icons/fc";
import { FcPieChart } from "react-icons/fc";

// import apies
import { getDateUserCount } from "../../../../apies/status/fetcher";

// import redux
import { setLookUpChatbotDatas } from "../../../../action/action";
import { setWeekUserCount } from "../../../../action/action";

// import components
import { UserCompanyCount } from "./components/userCompanyCount";
import { WeeklyUserCount } from "./components/weeklyUserCount";
import { UserKeyword } from "./components/userKeyword";
import { UserUseTime } from "./components/userUseTime";

import { TitleItem } from "componets/Item";
import { LocalSeeOutlined } from "@mui/icons-material";

import { setChatDatas } from "../../../../action/action";


const { RangePicker } = AntDatePicker;

const ChatbotDashboard = () => {

    const startDate = useSelector(state => state.startDate);
    const endDate = useSelector(state => state.endDate);

    const dispatch = useDispatch();

    // StartDate, EndDate 선택, Server에 전달
    const [date, setDate] = useState([null, null]);
    const [userCount, setUserCount] = useState(['']);

    const onChange = (date, dateString) => {
        setDate([dateString[0], dateString[1]]);
        localStorage.setItem('setChatDate1', dateString[0]);
        localStorage.setItem('setChatDate2', dateString[1]);
    };

    const dates1 = localStorage.getItem('setChatDate1');
    const dates2 = localStorage.getItem('setChatDate2');
    const userCounts = localStorage.getItem('ChatuserCount');

    const onSummit = () => {
        // ChatData 테스트 이용률 집계
        let chatDataCount = localStorage.getItem('ChatDataCount');

        if (chatDataCount === null || chatDataCount === "null") {
            chatDataCount = 0;
        } else {
            chatDataCount = parseInt(chatDataCount);
        }

        chatDataCount += 1;
        localStorage.setItem('ChatDataCount', JSON.stringify(chatDataCount));

        // TEST
        dispatch(setChatDatas(chatDataCount))

        // ChatData 일일 이용횟수 집계
        const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD

        const dailyStoredData = localStorage.getItem(`ChatDataCounts-${today}`);
        const dailyDataCount = parseInt(dailyStoredData, 10) || 0;

        const dailyCounts = dailyDataCount + 1;

        // const dailyChatDataCount = {
        //     count: dailyCounts,
        //     timeStamp: new Date().toISOString()
        // };
        localStorage.setItem(`ChatDataCounts-${today}`, dailyCounts);

        let formData = [date[0], date[1]];
        if (date[0] != null && date[1] != null) {

            getDateUserCount(formData)
                .then(response => {
                    const userCount = response.data[0];

                    if (userCount === 0) {
                        Modal.warning({
                            title: '선택한 기간에 대한 데이터가 없습니다.',
                            content: '날짜 범위를 다시 선택해주세요. 확인 클릭 시, 날짜 선택화면으로 이동합니다.',
                            okText: '확인',
                            onOk: () => window.location.reload()
                        });
                    }

                    const WeekUserCount = [];

                    WeekUserCount.push(Object.keys(response.data[1]));
                    WeekUserCount.push(Object.values(response.data[1]));

                    setUserCount(userCount);
                    localStorage.setItem('ChatuserCount', userCount);

                    // StartDate, endDate 저장
                    dispatch(setLookUpChatbotDatas(date[0], date[1]));
                    // 컴포넌트에 값 전달
                    dispatch(setWeekUserCount(WeekUserCount));
                }
                )
        }

        else {
            Modal.error({
                title: '경고',
                content: '날짜를 선택해주세요.',
            });
        }

    }

    if (startDate != null && endDate != null) {
        return (
            <Layout.Content>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={15}>
                            <TitleItem
                            >
                                <div className="title-area">
                                    <div className="main-title">
                                        챗봇 대시보드
                                    </div>
                                    <p></p>
                                    <div className="sub-title">
                                        에임메드 챗봇 샘플 데이터를 활용한 대시보드입니다.
                                    </div>
                                </div></TitleItem>
                        </Grid>
                        <Grid item xs={20}>
                            <TitleItem>
                                <div className="tech-area" style={{ display: 'grid', gridTemplateColumns: '50% 50%', padding: '20px', backgroundColor: '#eff1f6' }}>
                                    <div style={{ paddingRight: '20px', width: '100%' }}>
                                        <div style={{ display: 'grid', gridTemplateColumns: '49% 2% 49%' }}>
                                            <TitleItem style={{ width: '100%', paddingRight: '10px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px', fontWeight: 'bold', marginBottom: '5px' }}>
                                                    <FcCalendar size={33} style={{ width: '55px' }} />
                                                    조회기간
                                                </div>
                                                <div style={{ fontSize: '15px', textAlign: 'center', backgroundColor: '#eff1f6', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px', fontWeight: 'bold' }}>
                                                    {dates1}~{dates2}
                                                </div>
                                            </TitleItem>
                                            <div></div>
                                            <TitleItem style={{ width: '100%' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px', fontWeight: 'bold', marginBottom: '5px' }}>
                                                    <FcConferenceCall size={33} style={{ width: '55px' }} />
                                                    총 이용자 수
                                                </div>
                                                <div style={{ fontSize: '15px', textAlign: 'center', backgroundColor: '#eff1f6', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px', fontWeight: 'bold' }}>
                                                    {userCounts}명
                                                </div>
                                            </TitleItem>
                                        </div>
                                        <TitleItem style={{ marginTop: '15px', width: '100%', height: '680px', border: '2px solid rgb(55, 71, 104)', borderRadius: '36px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <div style={{ fontSize: '15px', textAlign: 'left', fontWeight: 'bold', marginBottom: '20px', marginTop: '20px' }}>사용자가 가장 많이 물어 본 키워드</div>
                                            <UserKeyword />
                                        </TitleItem>

                                    </div>
                                    <div>
                                        <TitleItem style={{ fontSize: '15px', textAlign: 'left', fontWeight: 'bold', marginBottom: '20px', width: '100%', border: '2px solid rgb(55, 71, 104)', borderRadius: '2px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', }}>주차 별 이용자 수<WeeklyUserCount /></TitleItem>
                                        <TitleItem style={{ fontSize: '15px', textAlign: 'left', fontWeight: 'bold', marginBottom: '20px', width: '100%', border: '2px solid rgb(55, 71, 104)', borderRadius: '22px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', }}>이용자 기업 통계<UserCompanyCount /></TitleItem>
                                        <TitleItem style={{ fontSize: '15px', textAlign: 'left', fontWeight: 'bold', marginBottom: '20px', width: '100%', height: '16.5%', border: '2px solid rgb(55, 71, 104)', borderRadius: '22px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', }}>평균 챗봇 체류 시간<UserUseTime /></TitleItem>
                                    </div>
                                </div>
                            </TitleItem>
                        </Grid>
                    </Grid>
                </Box>

            </Layout.Content>
        );
    }

    else if (startDate === null && endDate === null) {
        return (
            <Layout.Content>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={15}>
                            <TitleItem
                            >
                                <div className="title-area">
                                    <div className="main-title">
                                        챗봇 대시보드
                                    </div>
                                    <p></p>
                                    <div className="sub-title">
                                        에임메드 챗봇 샘플 데이터를 활용한 대시보드입니다.
                                    </div>
                                </div></TitleItem>
                        </Grid>
                        <Grid item xs={20}>
                            <TitleItem>
                                <div className="tech-area" style={{ height: '800px', paddingTop: '15%' }}>
                                    <div>
                                        <h3>챗봇 대시보드</h3>
                                        챗봇 샘플 데이터를 활용한 통계 대시보드입니다.
                                        <p></p>
                                        데이터의 기간을 선택하면 해당 대시보드 화면을 볼 수 있습니다.
                                        <p></p>
                                    </div>
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            format={{
                                                format: 'YYYY-MM-DD',
                                                type: 'mask',
                                            }}
                                            defaultValue={dayjs('2024-03-01', 'YYYY-MM-DD')}
                                            minDate={dayjs('2024-03-01', 'YYYY-MM-DD')}
                                            maxDate={dayjs('2024-04-05', 'YYYY-MM-DD')}
                                            onChange={onChange} />
                                    </Space>
                                    <p></p>
                                    <Button type="primary" onClick={onSummit}>테스트 시작하기</Button>
                                </div>
                            </TitleItem>
                        </Grid>
                    </Grid>
                </Box>

            </Layout.Content>
        );
    }
}

const ChatbotData = () => {
    return (
        <TechIndex>
            <ChatbotDashboard />
        </TechIndex>
    );
};

export default ChatbotData;
