import React from 'react';
import { useState, useEffect } from 'react';
import { Layout } from 'antd';

import AdminIndex from '../AdminIndex';
import '../../css/HomeC.css';
import '../../css/AntC.css';
import axios from 'axios';

const { Content } = Layout;

const apiUrl = 'http://43.202.52.195:8080/hi';

const Today = () => {

    const [userCount, setUserCount] = useState(0);

    useEffect(() => {
        axios(apiUrl)
            .then(response => {
                setUserCount(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <Content>
        {/* 타이틀 */}
        <div className="title-area">
            <h5 className="main-title">
            Explore Topics
            </h5>
            <p className="sub-title">
            We did our best to cover all the topics related to this site. Each topic has been neatly categorized to help you quickly find the information you're looking for.
            </p>
        </div>

        {/* 기술영역 */}
        <div className="tech-area">
            <div className="circle">
                <span type="text" style={{ display: 'block', textAlign: 'center', marginTop: '45px' }}>Today:{userCount}</span>
            </div>
        </div>

        </Content>
    );
};


const AdminHome = () =>{
    return(
        <AdminIndex>
        <Today/>
        </AdminIndex>
            
    );
};

export default AdminHome;