import axios from "axios";

const default_url = process.env.REACT_APP_API_URL;
const ai_url = 'https://adm.aimmed.com';

// TEST1212
const urls = {
    //DEFAULT URL 
    aiapi: default_url,

    //HER-A SUMMARY AND CASSIFY OPINION
    showTrendTables: `${default_url}/api/TrendPost/show`,
    creatTrendTables: `${default_url}/api/TrendPost/create`,
    saveFileTrendTables: `${default_url}/api/TrendPost/create/saveFile`,
    deleteTrendTables: `${default_url}/api/TrendPost/delete`,
    ShowEditTrendTables: `${default_url}/api/TrendPost/show/edit`,
    editTrendTables: `${default_url}/api/TrendPost/edit`,

    //HER-A SHOW STATISTICS 
    ShowStatisticsURL: `${ai_url}/text/statistics`,
    getStatisticsURL: `${ai_url}/text/statistics/get_sum`,
    // getDifferentStatisticsURL: `${ai_url}/text/statistics/get_different`,
    // getSameStatisticsURL:`${ai_url}/text/statistics/get_same`,
    getMoreStatisticsURL:`${ai_url}/text/statistics/get_more`
};

const token = localStorage.getItem('JWT-token');

export const getTrendPostTables = () => axios({
    method: 'get',
    url: urls.showTrendTables,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const createTrendPost = (formData) => axios({
    method: 'post',
    url: urls.creatTrendTables,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const saveFileTrendPost = (fileFormData) => axios({
    method: 'post',
    url: urls.saveFileTrendTables,
    data: fileFormData,
    headers: {
        "Content-Type": 'multipart/form-data',
    }
})

export const showTrendPost = (id) => axios({
    method: 'get',
    url: `${urls.showTrendTables}/${id}`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const deleteTrendPost = (id) => axios({
    method: 'delete',
    url: `${urls.deleteTrendTables}/${id}`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const ShowEditTrendPost = (id) => axios({
    method: 'get',
    url: `${urls.ShowEditTrendTables}/${id}`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const editTrendPost = (formData) => axios({
    method: 'post',
    url: urls.editTrendTables,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const heraStatisticsPost = (formData) => axios({
    method: 'post',
    url: urls.ShowStatisticsURL,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const heraStatisticsGetSum = (formData) => axios({
    method: 'post',
    url: urls.getStatisticsURL,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

// export const heraStatisticsGetDifferent = (formData) => {
//     const { itemCode, startDate, endDate } = formData;

//     return axios({
//         method: 'get',
//         url: `${urls.getDifferentStatisticsURL}/${itemCode}/${startDate}/${endDate}`,
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": token
//         }
//     });
// }

// export const heraStatisticsGetSame = (formData) => {
//     const { itemCode, startDate, endDate } = formData;

//     return axios({
//         method: 'get',
//         url: `${urls.getSameStatisticsURL}/${itemCode}/${startDate}/${endDate}`,
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": token
//         }
//     });
// }

export const heraStatisticsGetMore = (formData) => {
    const { itemCode, startDate, endDate } = formData;

    return axios({
        method: 'get',
        url: `${urls.getMoreStatisticsURL}/${itemCode}/${startDate}/${endDate}`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
}




