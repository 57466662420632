import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import '../css/LoginC.css';

// import icons
import { ExclamationCircleFilled } from '@ant-design/icons';

import { Modal, Space, Layout } from 'antd';
const { confirm } = Modal;

import { setUserName } from '../action/action';

function SignUp() {

    const dispatch = useDispatch();

    const [inputId, setInputId] = useState('')
    const [inputPw1, setInputPw1] = useState('')
    const [inputPw2, setInputPw2] = useState('')
    const [error, setError] = useState(null);

    const handleInputId = (e) => {
        setInputId(e.target.value)
    }

    const handleInputPw = (e) => {
        setInputPw1(e.target.value)
    }

    const handleInputPw2 = (e) => {
        setInputPw2(e.target.value)
    }

    // signUp 버튼 클릭 이벤트
    const onClickSignUp = () => {
        const data = {
            email: inputId,
            password1: inputPw1,
            password2: inputPw2
        };

            axios.post(`${process.env.REACT_APP_API_URL}/signUp`, null, { params: data })
                .then(res => {
                    console.log("OK");
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError("이메일과 비밀번호를 확인해주세요.");
                });
    }

    // const infoUserName = useSelector(state => state.infoUserName);
    // console.log('info',infoUserName);          

    return (
        <div className="main">
            <p className="sign" style={{ textAlign: "center" }}>Login</p>
            <input
                className="pass"
                type="text"
                name="email"
                style={{ textAlign: "center" }}
                placeholder="email"
                value={inputId}
                onChange={handleInputId}
            />
            <input
                className="pass"
                type="password"
                name="password"
                style={{ textAlign: "center" }}
                placeholder="Password"
                value={inputPw1}
                onChange={handleInputPw}
            />
            <input
                className="pass"
                type="password"
                name="password"
                style={{ textAlign: "center" }}
                placeholder="비밀번호를 한번 더 입력해주세요."
                value={inputPw2}
                onChange={handleInputPw2}
            />
            <button type="submit" className="submit" onClick={onClickSignUp} style={{ transform: "transformX:70px" }}>signUp</button>
            {error && <p className="error" style={{ color: "red", textAlign: "center" }}>{error}</p>} {/* 에러 메시지 출력 */}
            {/* <p className="forgot" style={{ textAlign: "center" }}><a href="/">Forgot Password?</a></p> */}
        </div>
    );
}


export default SignUp;