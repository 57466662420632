import TechIndex from "../../../Techindex";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { Layout, Button, Space, Table } from 'antd';

// import apies
// import { heraStatisticsGetDifferent } from "apies/status/postFetcher";
// import { heraStatisticsGetSame } from "apies/status/postFetcher";
import { heraStatisticsGetMore } from "apies/status/postFetcher";

import { TitleItem } from "componets/Item";

const Hera_statistics_More_Main = () => {

    const { key } = useParams();
    const startDate = localStorage.getItem('start_date')
    const endDate = localStorage.getItem('end_date')

    const [dataSource, setDifferentDataSource] = useState([]);

    const [differentAllCounts, setDifferentAllCounts] = useState([]);

    const [sameAllCounts, setSameAllCounts] = useState(0);

    const codeMapping = {
        'E0000332': '위내시경',
        'E0001442': '위내시경 조직검사',
        'E0000367': '대장내시경',
        'E0001496': '대장내시경 조직검사',
        'E0000333': '위장조영술',
        'E0001441': '경동맥초음파',
        'E0001982': '관상동맥석회화CT',
        'E0001432': '갑상선초음파',
        'E0002168': '유방초음파',
        'E0002169': '난소초음파',
        'E0000543': '전립선초음파',
        'E0000798': '뇌MRI',
        'E0000851': '뇌MRA',
        'E0001477': '경추MRI',
        'E0001481': '요추MRI',
        'E0001379': '뇌CT',
        'E0000706': '흉부CT',
        'E0001579': '복부CT',
        'E0001382': '복부촬영',
        'E0001570': '경추CT',
        'E0001558': '요추CT',
        'E0001800': '동맥경화도검사',
        'E0000586': '자궁경부암검사',
        'E0000223': '심전도',
        'E0000286': '흉부촬영',
        'E0002167': '유방촬영',
        'E0001569': '경추촬영',
        'E0001560': '요추촬영',
        'E0002230': '상복부초음파',
        'E0001423': '자궁초음파',
        'E0001864': '질초음파',
        'E0001433': '간초음파',
        'E0001438': '담낭초음파',
        'E0000447': '췌장초음파',
        'E0002166': '신장초음파',
        'E0000487': '비장초음파'
    };


    const columns = [
        {
            title: 'AI 판정',
            dataIndex: 'aiResult',
            key: '2',
        },
        {
            title: '사람 판정',
            dataIndex: 'personResult',
            key: '3',
        },
        {
            title: '건수',
            dataIndex: 'count',
            key: '4',
        },
    ];

    const getResultLabel = (result, type) => {
        const labels = {
            '011': type === 'ai' ? '정상' : '정상',
            '012': type === 'ai' ? '유소견' : '유소견',
            '014': type === 'ai' ? '미분류' : '미분류',
            '015': type === 'ai' ? '중증유소견 후보' : '중증 유소견 후보',

            '001': type === 'person' ? '정상' : '정상',
            '002': type === 'person' ? '유소견' : '유소견',
            '003': type === 'person' ? '중증유소견' : '중증유소견',
            // '004': type ==='person' ? '정상':'정상',

        }
        return labels[result] || '알 수 없음';
    }


    useEffect(() => {
        const formData = {
            itemCode: key,
            startDate: startDate,
            endDate: endDate
        };

        heraStatisticsGetMore(formData)
            .then(response => {
                const formattedData = response.data.map(item => ({
                    aiResult: getResultLabel(item[0], 'ai'),
                    personResult: getResultLabel(item[1], 'person'),
                    count: item[2],
                }));

                let totalCounts = 0;
                let differentCounts = 0;

                formattedData.forEach(item => {
                    if (item.aiResult === item.personResult) {
                        totalCounts += item.count;
                    }
                    else if (item.aiResult == '중증유소견 후보' && item.personResult == '중증유소견') {
                        totalCounts += item.count;
                    }
                    else {
                        differentCounts += item.count;
                    }
                });

                setDifferentDataSource(formattedData);
                setDifferentAllCounts(differentCounts);
                setSameAllCounts(totalCounts);
            });
    }, [key, startDate, endDate]);

    const getRowClassName = (record) => {
        return (record.aiResult === record.personResult ||
            (record.aiResult === '중증유소견 후보' && record.personResult === '중증유소견'))
            ? 'highlight-row' : '';
    };



    return (
        <Layout.Content>
            {/* 타이틀 */}
            <TitleItem>
                <div className="title-area">
                    <div className="main-title">
                        Her-a 모델 소견 정확도 확인 상세 페이지
                    </div>
                    <div className="sub-title">
                        건강검진 종합소견의 정확도를 확인하는 페이지 입니다.
                    </div>
                </div>
            </TitleItem>
            <p></p>
            <TitleItem>
                <div className="tech-area" style={{ height: '1000px', paddingTop: '5%' }}>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {startDate} ~ {endDate} {codeMapping[key] || '알 수 없음'}
                    </div>

                    <p></p>
                    <div style={{ paddingRight: '75%', fontSize: '16px', fontWeight: 'bold' }}>전체: {sameAllCounts + differentAllCounts}건
                        <span style={{ paddingLeft: '5%' }}>일치:{sameAllCounts}건 </span>
                        <span style={{ paddingLeft: '5%' }}>오류:{differentAllCounts}건 </span>
                    </div>
                    <p></p>
                    <Table dataSource={dataSource} columns={columns} className='tables' rowClassName={getRowClassName} />
                </div>
            </TitleItem>
        </Layout.Content>
    );
};

const Hera_statistics_More = () => {
    return (
        <TechIndex>
            <Hera_statistics_More_Main />
        </TechIndex>
    );
};

export default Hera_statistics_More;
