import { Button, Layout, Radio } from 'antd';
import TechIndex from "../../../Techindex";
import React, { useState } from 'react';

import { TitleItem } from 'componets/Item';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const RadioCheck = ({ value, onChange }) => {
  return (
    <Radio.Group onChange={onChange} value={value}>
      <Radio value={1}>케어 메신저</Radio>
      <Radio value={2}>상담 챗봇</Radio>
    </Radio.Group>
  );
};

const ChatbotMain = () => {
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const handleTestStart = () => {
    // 로컬 스토리지에서 ChatCount 가져오기
    let chatCount = localStorage.getItem('ChatCount');
    
    // chatCount가 null인 경우 초기화
    if (chatCount === null) {
      chatCount = 0;
    } else {
      chatCount = parseInt(chatCount, 10);
      if (isNaN(chatCount)) chatCount = 0; // NaN 경우 초기화
    }

    // chatCount 증가 및 저장
    chatCount += 1;
    localStorage.setItem('ChatCount', chatCount.toString());

    // Chatbot 일일 이용횟수 집계
    const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD
    const dailyStoredData = localStorage.getItem(`ChatCounts-${today}`);
    const dailyDataCount = parseInt(dailyStoredData, 10) || 0;
    
    const dailyCounts = dailyDataCount + 1;
    // const dailyChatCount = {
    //   count: dailyCounts,
    //   // timeStamp: new Date().toISOString()
    // };

    localStorage.setItem(`ChatCounts-${today}`, dailyCounts);

    // 페이지 이동
    if (value === 1) {
      window.location.href = "/chatbot/care";
    } else if (value === 2) {
      window.location.href = "/chatbot/consult";
    }
  };

  return (
    <Layout.Content>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={15}>
            <TitleItem
            >
              {/* 타이틀 */}
              <div className="title-area">
                <div className="main-title">
                  챗봇 대시보드 테스트 화면
                </div>
                <p></p>
                <div className="sub-title">
                  시나리오 기반 챗봇 유형을 확인할 수 있는 테스트 화면입니다.
                </div>
              </div></TitleItem>
            {/* 기술영역 */}
          </Grid>
          <Grid item xs={20}>
            <TitleItem>
              <div className="tech-area" style={{ height: '800px' }}>
                <div>
                  <h3>샘플 챗봇 선택하기</h3>
                  시나리오 기반 챗봇은 케어 메신저, 사용자 입력 시나리오 챗봇은 상담 챗봇입니다.
                  <p></p>
                  <div style={{ margin: 'auto', width: '400px', border: '1px solid rgb(226, 226, 226)', padding: '7px', borderRadius: '5%' }}>
                    <RadioCheck value={value} onChange={onChange} />
                  </div>
                </div>
                <p></p>
                <Button type="primary" onClick={handleTestStart}>테스트 시작하기</Button>
              </div></TitleItem>
          </Grid>
        </Grid>
      </Box>

    </Layout.Content>
  );
};

const Chatbot = () => {
  return (
    <TechIndex>
      <ChatbotMain />
    </TechIndex>
  );
};


export default Chatbot;
