import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '@mui/x-charts/BarChart';

// import apies
import { getCustomerByAge } from 'apies/status/fetcher';

export const AgeGroupInspectionChart = () => {
  const startHealthDate = useSelector(state => state.startHealthDate);
  const endHealthDate = useSelector(state => state.endHealthDate);

  const [counts, setCounts] = useState([]);
  const [age, setAge] = useState([]);

  const [ageCounts, setAgeCounts] = useState({
    twenty: 0,
    thirty: 0,
    forty: 0,
    fifty: 0,
    sixty: 0
  });

  useEffect(() => {
    if (startHealthDate && endHealthDate) {
      const formData = [startHealthDate, endHealthDate];

      getCustomerByAge(formData)
        .then(response => {
          const data = response.data;
          setCounts(data.map(item => item.count));
          setAge(data.map(item => item.age));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [startHealthDate, endHealthDate]);

  useEffect(() => {
    const countByAgeGroup = {
      twenty: 0,
      thirty: 0,
      forty: 0,
      fifty: 0,
      sixty: 0
    };

    age.forEach((ageValue, index) => {
      const count = counts[index];
      if (ageValue.startsWith('2')) {
        countByAgeGroup.twenty += count;
      } else if (ageValue.startsWith('3')) {
        countByAgeGroup.thirty += count;
      } else if (ageValue.startsWith('4')) {
        countByAgeGroup.forty += count;
      } else if (ageValue.startsWith('5')) {
        countByAgeGroup.fifty += count;
      } else if (ageValue.startsWith('6')) {
        countByAgeGroup.sixty += count;
      }
    });

    setAgeCounts(countByAgeGroup);
  }, [age, counts]);

  return (
    <div>
      <BarChart
        xAxis={[{
          scaleType: 'band',
          data: ['20대', '30대', '40대', '50대', '60대'],
          labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' },
          fontSize: 14,
          colorMap: {
            type:"ordinal",
            values:['20대', '30대', '40대', '50대', '60대'],
            colors:['#89c2d9', '#468faf', '#468faf', '#2c7da0', '#014f86'],
          }
        }]}
        series={[
          {
            data: [ageCounts.twenty, ageCounts.thirty, ageCounts.forty, ageCounts.fifty, ageCounts.sixty],
            labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' }
          },
        ]}
        width={310}
        height={300}
        barLabel="value"
      />
    </div>
  );
}

export default AgeGroupInspectionChart;
