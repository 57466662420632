import TechIndex from "../../../Techindex";
import React, { useEffect, useState } from 'react';

import { Layout, Button, Space, Table, DatePicker as AntDatePicker } from 'antd';
import dayjs from 'dayjs';
dayjs.extend(customParseFormat);

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TitleItem } from "componets/Item";

import { heraStatisticsPost } from "apies/status/postFetcher";
import { heraStatisticsGetSum } from "apies/status/postFetcher";

import { useNavigate } from 'react-router-dom';

const { RangePicker } = AntDatePicker;

const codeMapping = {
    'E0000332': '위내시경',
    'E0001442': '위내시경 조직검사',
    'E0000367': '대장내시경',
    'E0001496': '대장내시경 조직검사',
    'E0000333': '위장조영술',
    'E0001441': '경동맥초음파',
    'E0001982': '관상동맥석회화CT',
    'E0001432': '갑상선초음파',
    'E0002168': '유방초음파',
    'E0002169': '난소초음파',
    'E0000543': '전립선초음파',
    'E0000798': '뇌MRI',
    'E0000851': '뇌MRA',
    'E0001477': '경추MRI',
    'E0001481': '요추MRI',
    'E0001379': '뇌CT',
    'E0000706': '흉부CT',
    'E0001579': '복부CT',
    'E0001382': '복부촬영',
    'E0001570': '경추CT',
    'E0001558': '요추CT',
    'E0001800': '동맥경화도검사',
    'E0000586': '자궁경부암검사',
    'E0000223': '심전도',
    'E0000286': '흉부촬영',
    'E0002167': '유방촬영',
    'E0001569': '경추촬영',
    'E0001560': '요추촬영',
    'E0002230': '상복부초음파',
    'E0001423': '자궁초음파',
    'E0001864': '질초음파',
    'E0001433': '간초음파',
    'E0001438': '담낭초음파',
    'E0000447': '췌장초음파',
    'E0002166': '신장초음파',
    'E0000487': '비장초음파'
};

const Hera_statistics_Main = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [sum, setSum] = useState([0]);
    const [correctSum, setCorrectSum] = useState([0]);
    const [accuracy, setAccuracy] = useState([0]);

    const onChange = (date, dateString) => {
        localStorage.setItem('start_date',dateString[0])
        localStorage.setItem('end_date',dateString[1])
        setDate([dateString[0], dateString[1]]);
    };

    const columns = [
        {
            title: '검사명',
            dataIndex: 'itemCode',
            key: '1',
        },
        {
            title: '전체',
            dataIndex: 'count',
            key: '2',
        },
        {
            title: '정상 판정',
            dataIndex: 'correctCount',
            key: '3',
        },
        {
            title: '정확도',
            dataIndex: 'percentage',
            key: '4',
        },
        {
            title: '상세',
            dataIndex: 'more',
            key: '5',
            render: (text, record) => (
                <Button
                    type="primary"
                    onClick={() => navigate(`/NLP/her-a/statistics/${record.key}`)}
                >
                    more
                </Button>
            ),
        },
    ];

    const onSummit = () => {
        let formData = [date[0], date[1]];
        heraStatisticsPost(formData)
            .then(response => {
                console.log(response.data);
                const itemCode = response.data[0];
                const count = response.data[1];
                const correctCount = response.data[2];
                const percentage = response.data[3];

                // 정답 개수
                const correctCountSum = correctCount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setCorrectSum(correctCountSum);

                const resultList = itemCode.map((code, index) => ({
                    key: code,
                    itemCode: codeMapping[code] || code,
                    count: count[index] || 0,
                    correctCount: correctCount[index] || 0,
                    percentage: percentage[index] || 0,
                }));

                setDataSource(resultList);

                // get_sum
                heraStatisticsGetSum(formData)
                    .then(response => {
                        setSum(response.data)
                    }
                    );
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };
    useEffect(() => {
        if (sum > 0) {
            const accuracyRate = ((correctSum / sum) * 100).toFixed(2);
            setAccuracy(accuracyRate);
        } else {
            setAccuracy(0);
        }
    }, [correctSum, sum]);

    return (
        <Layout.Content>
            {/* 타이틀 */}
            <TitleItem>
                <div className="title-area">
                    <div className="main-title">
                        Her-a 모델 소견 정확도 확인 페이지
                    </div>
                    <div className="sub-title">
                        건강검진 종합소견의 정확도를 확인하는 페이지 입니다.
                    </div>
                </div>
            </TitleItem>
            <p></p>
            <TitleItem>
                <div className="tech-area" style={{ height: '1000px', paddingTop: '5%' }}>
                    <div>
                        날짜와 검사 대상을 선택해주세요.
                        <p></p>
                    </div>
                    <Space direction="vertical" size={12}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RangePicker
                                format={{
                                    format: 'YYYY-MM-DD',
                                    type: 'mask',
                                }}
                                defaultValue={dayjs('2024-01-01', 'YYYY-MM-DD')}
                                onChange={onChange}
                            />
                        </div>
                    </Space>

                    <p></p>
                    <Button type="primary" onClick={onSummit}>결과 확인하기</Button>
                    <p></p>
                    <div>※가능한 한 너무 넓은 범위의 데이터를 선택하지 않으시기를 권장합니다.</div>
                    <p></p>
                    <div style={{ paddingRight: '75%', fontSize: '16px', fontWeight: 'bold' }}>전체: {sum}건
                        <span style={{ paddingLeft: '5%' }}>오류: {sum - correctSum}건({(100-accuracy).toFixed(2)}%)</span>
                    </div>

                    <p></p>
                    <Table dataSource={dataSource} columns={columns} className='tables' />
                </div>
            </TitleItem>
        </Layout.Content>
    );
};

const Hera_statistics = () => {
    return (
        <TechIndex>
            <Hera_statistics_Main />
        </TechIndex>
    );
};

export default Hera_statistics;
