import React from 'react';
import { LineChartOutlined,MessageOutlined,WechatOutlined ,FileImageOutlined,ExperimentOutlined,FundProjectionScreenOutlined,ToolOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import './css/AntC.css';

import { HiOutlineHome } from "react-icons/hi2";

// import apies
import { logoutApi } from 'apies/status/fetcher';

import base64 from 'base-64'

const { Header, Content, Footer, Sider } = Layout;
const items1 = ['Home', 'List', 'App'];
const items2 = [
  {
    key: 'home',
    label: <Link to="/home"> <div style={{ color: 'white',fontWeight:'bold',fontSize:'18px' }}>Home</div></Link>,
  },

  {
    key: 'MainMenu_1',
    icon: <LineChartOutlined style={{color:'white',fontSize:'25px'}}/>,
    label: <Link to="/dashboard/info"> <div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>통계</div></Link>,
    children: [
      {
        key: 'sub1',
        label: <Link to="../dashboard/healthStatistics"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>건강통계 데이터</div></Link>
      },
      {
        key: 'sub2',
        label: <Link to="../dashboard/chatbotData"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>챗봇 데이터</div></Link>
      },
      {
        key: 'sub3',
        label: <Link to="../dashboard/healthData"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>헬스 데이터</div></Link>
      }
    ]
  },
  {
    key: 'MainMenu_2',
    icon: <MessageOutlined style={{color:'white',fontSize:'25px'}}/>,
    label: <Link to="/chatbot/info"> <div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>챗봇</div></Link>,
    children: [
      {
        key: 'sub4',
        label: <Link to="/chatbot"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>챗봇</div></Link>
      },
      {
        key: 'sub5',
        label: <Link to="/aichatbot"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>AI챗봇</div></Link>
      }
    ]
  },
  {
    key: 'MainMenu_3',
    icon: <FileImageOutlined style={{color:'white',fontSize:'25px'}}/>,
    label: <Link to="/img/info"> <div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>이미지</div></Link>,
    children: [
      {
        key: 'sub6',
        label: <Link to="/img/OCR"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>OCR</div></Link>
      }
    ]
  },
  {
    key: 'MainMenu_4',
    icon: <ExperimentOutlined style={{color:'white',fontSize:'25px'}}/>,
    label: <Link to="/NLP/info"> <div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>NLP</div></Link>,
    children: [
      {
        key: 'sub7',
        label: <Link to="/NLP/her-a"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>Her-A</div></Link>
      },
      {
        key: 'sub8',
        label: <Link to="/NLP/her-a/statistics"><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px' }}>Her-A statistics</div></Link>
      }
    ]
  },
];

const items3 = [
  {
    key: 'Tech-Trend',
    icon: <FundProjectionScreenOutlined style={{color:'white',fontSize:'25px'}}/>,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/techBoard" style={{ marginLeft: '13px' }}><div style={{ color: 'white',fontWeight:'bold',fontSize:'14px'  }}>기술 트렌드</div></Link>
      </div>
    ),
  },
  {
    key: 'Tech-Propose',
    icon: <ToolOutlined style={{color:'white',fontSize:'25px'}}/>,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/techBoard" style={{ marginLeft: '13px' }}><div style={{ color: 'white' ,fontWeight:'bold',fontSize:'14px' }}>기술 제안</div></Link>
      </div>
    ),
  },
  {
    key: 'copy-right',
    label: <div style={{ fontSize: '9px', fontWeight:'bold' }}>ⓒ2024. Aimmed All Rights reserved. </div>
  }
]

const TechIndex = ({ children }) => {

  // 토큰으로 사용자 검증 
  let token = localStorage.getItem('JWT-token')
  let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
  let dec = JSON.parse(base64.decode(payload));
  let roles = dec['roles']

  // 로컬 스트로지에 권한 저장
  localStorage.setItem('roles', roles);
  console.log(roles);

  // 화면 출력 용 사용자 닉네임 저장
  let userName = localStorage.getItem('userName');


  // if (roles == "USER") {
  //   console.log("user");
  // } else {
  //   console.log("admin");
  // }

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const logoutClick = () => {
    if(userName!=null){
      logoutApi()
      .then(response=>{
          console.log(response.data);

          // localstorage delete
          localStorage.removeItem('JWT_token')
          localStorage.removeItem('roles')
          localStorage.removeItem('userName')
          localStorage.removeItem('changePWID')

          window.location.href = '/login'
      })
    }
    else{
      window.location.reload();
    }
    
  }

  return (
    <Layout style={{ width: '100%' }}>
      <div className="demo-logo" />
      {/* {roles === "USER" ? (
        <span></span>
      ) : (
        <a href='../admin/home' style={{marginLeft:'48px'}}>관리자 수정모드</a>
      )} */}
      {/* HomeOutlined 아이콘*/}
      <div style={{backgroundColor:'#eff1f6'}}>
        <a onClick={logoutClick} style={{ float: 'right', marginLeft: 'auto', paddingLeft: '10px' }}>로그아웃</a>
        <span style={{ float: 'right', marginLeft: 'auto' }}>{userName}님 환영합니다. </span>
      </div>
      <Content
        style={{
          padding: '0 32px',
          paddingBottom: '30px',
          // overflow: 'auto',
          // minHeight: 'calc(100vh - 30px)'
        }}
      >
        <Breadcrumb
          style={{
            margin: '5px 0',
          }}
          items={items1}
        />
        <Layout
          style={{
            paddingTop: ' px 0',
            paddingLeft: '24px 0',
            paddingRight: '24px 0',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}

        >
          <Sider
            style={{
              backgroundColor:'#374768',
            }}
          // width={200}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['MainMenu_1', 'MainMenu_2', 'MainMenu_3', 'MainMenu_4']}
              style={{
                // height: '65%',
                backgroundColor: '#374768',
                borderRadius: '1%',
                // width:'100%' ,
                maxWidth:'None',
                // position: 'fixed',
                // borderBottom:'2px solid rgb(226, 226, 226)'
                paddingBottom:'420px'
              }}
              items={items2}
            />
            {/* <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{
                // height: '15%', 
                textAlign: 'center',
                borderTop: '2.5px solid rgb(226, 226, 226)',
                backgroundColor: '#374768',
                // position:'sticky',
                zIndex: '100', 
                position: 'fixed',
                width:'200px'
              }}
              items={items3}
            /> */}

          </Sider>
          <Content
            style={{
              padding: '0 25px',
              backgroundColor: '#eff1f6',
              display:'flex',
              minHeight: 350,
            }}
          >
            {children}
          </Content>
        </Layout>
        {/* <Footer style={{height:'10%'}}>footer</Footer> */}
      </Content>
      {/* <Footer>footer</Footer> */}
    </Layout>
  );
};
export default TechIndex;

