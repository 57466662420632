import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

// import apies
import { getAverageTime } from '../../../../../../apies/status/fetcher'

export const UserUseTime = () => {

    const startDate = useSelector(state => state.startDate);
    const endDate = useSelector(state => state.endDate);


    const [averageTime, setAverageTime] = useState(['','','']);

    useEffect(() => {

        if (startDate && endDate) {
            let form = [startDate, endDate]
            getAverageTime(form)
                .then(response => {
                    console.log('averageTime',response.data)

                    const minutes = Math.floor(response.data);
                    const remainingSeconds = Math.floor((response.data - minutes) * 60 );

                    const seconds = Math.floor(remainingSeconds);
                    const milliseconds = Math.floor((remainingSeconds - seconds) * 1000 );

                    setAverageTime([minutes, seconds, milliseconds]);
                })
        }

    }, [startDate, endDate, setAverageTime]);



    return (
        <div style={{ textAlign: 'center', fontSize:'14px', justifyItems:'center',marginTop:'5%' }}>{averageTime[0]}분 {averageTime[1]}초 {averageTime[2]}</div>
    )
}
