import TechIndex from "Techindex";
import { useSelector, useDispatch } from 'react-redux';

// import antd
import { Radio, Layout, Input, Spin } from 'antd';
import { Card, Space } from 'antd';
import { Button, Modal } from 'antd';

import { TitleItem } from "componets/Item";

export const Info = () => {
    return (
        <Layout.Content>
            {/* 타이틀 */}
            <TitleItem >
                <div className="title-area" >
                    <p className="main-title" style={{ fontSize: '17px' }} >
                        챗봇
                    </p>
                </div>
            </TitleItem>
            <TitleItem style={{ marginTop: '1%' }}>
                <div className="tech-area">
                    <div style={{ textAlign: 'left', fontSize:'15px'  }}>
                        <span style={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>챗</span>
                        <span style={{ fontWeight: '470', color: '#000000' }}>봇은 대화형 인터페이스를 통해 인간과 자동화 된 시스템 간의 상호작용을 지원하는 프로그램 또는 컴퓨터 애플리케이션 입니다.</span>
                        <p></p>
                        에임메드에서 활용하는 챗봇은 크게 두 가지로, 챗봇과 AI챗봇이 있습니다.
                        <p></p>
                        챗봇은 시나리오에 적혀진 순서대로 메시지가 노출되는 케어메시지 유형과 사용자가 입력창에 문구를 작성하면 챗봇이 미리 준비한 답변을 송출하는 유형인 케어 챗봇이 있습니다.
                        <br/>
                        AI챗봇은 Open AI에서 개발한 Chat GPT를 활용하거나, 첨부한 문서 내에서 챗봇이 답변을 검색하고 요약하는 RAG 기능을 LangChain 프레임워크로 구현할 수 있습니다.
                        <p></p>
                        <img src="/files/chatbot/chatbotinfo.png" alt="chat" style={{ width: '23%' }} />
                        <span style={{ marginLeft: '2%' }}><img src="/files/chatbot/AIchatbotinfo.png" alt="aiChat" style={{ width: '23%' }} /></span>
                        <Card title="Ⅰ챗봇" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%', color:'#000000', fontSize:'15px'  }} >
                            <p>챗봇에는 시나리오대로 노출되는 케이메시지와 사용자 발화에 따라 노출되는 케어 챗봇이 있습니다.</p>
                            <p style={{ fontWeight: 'bold' }}>ⅰ 케어 메시지</p>
                            <p>⦁ 시나리오 순서대로 메시지가 노출되는 챗봇 유형입니다.</p>
                            <img src="/files/chatbot/chatbot3.png" alt="chat3" style={{ width: '23%' }} />
                            <p style={{ fontWeight: 'bold' }}>ⅱ 케어 챗봇</p>
                            <p>⦁ 사용자가 채팅창에 입력을 하는 대로 챗봇이 대답하는 유형입니다.</p>
                            <img src="/files/chatbot/chatbot4.png" alt="chat4" style={{ width: '23%' }} />
                        </Card>
                        <Card title="Ⅱ AI챗봇" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%' , color:'#000000' , fontSize:'15px' }} >
                            <p>AI챗봇은 OpenAI의 GPT나 RAG를 활용하여 요금이 과금 되는 형태의 챗봇 입니다.</p>
                            <p>⦁Chat GTP: OpenAI에서 개발한 인공지능 언어 모델로, 인간과 자연스럽게 대화를 생성하는 챗봇입니다.</p>
                            <p>⦁RAG(Retrieval Augmented Generation): 정보 검색 구성 요소와 텍스트 생성 모델을 결합 한 방식으로, 간단히 말하면 사용자가 첨부한 Word 파일 내에서 챗봇이 답변을 찾아서 대화를 생성할 수 있습니다.</p>
                            <img src="/files/chatbot/chatbot5.png"  alt="chat5" style={{ width: '23%' }} /><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*PDF파일이 적용 된 RAG AI챗봇 예시
                        </Card>
                        <Card title="Ⅲ AI챗봇 테스트 하기" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%' , color:'#000000' , fontSize:'15px' }} >
                        <img src="/files/chatbot/AIchattest.png"  alt="aiChatTest" style={{ width: '75%' }} />
                            <p>1) 비밀번호 입력: AI챗봇은 요금이 발생하는 유료버전이기 때문에 비밀번호를 입력해야 테스트를 진행할 수 있습니다.
                                <br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;정확한 비밀번호를 입력하지 않으면 테스트를 진행할 수 없습니다. </p>
                            <p>2) 프롬프트 입력: 챗봇에 특정 작업이나 행동을 수행하기 위한 지시 또는 명령어를 뜻합니다.
                                <br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;챗봇의 캐릭터나 행동을 지시하는 문구를 작성하면 됩니다 (예: 한글로 말해줘. 챗봇 너는 이름이 뭐야 등)
                            </p>
                            <p>3) RAG 첨부: 챗봇이 한정된 스크립트 내에서 대답하길 원한다면 RAG 파일을 첨부해 주세요.
                                <br/>&emsp;&emsp;&emsp;&emsp;&emsp;챗봇이 검색할 수 있는 자료를 첨부하되, 첨부 가능한 확장자는 word, pdf, txt입니다.
                            </p>
                        </Card>
                    </div>
                </div>

            </TitleItem>
        </Layout.Content>
    )
}

const ChatbotInfo = () => {
    return (
        <TechIndex>
            <Info />
        </TechIndex>
    );
};

export default ChatbotInfo;
