import React from "react";
import { Navigate } from "react-router-dom";

const AdminRouter = ({ authenticated, component: Component }) => {
    let userRole = localStorage.getItem('roles');

    if (authenticated) {
        if (userRole === 'ADMIN') {
            return Component;
        }
        else {
            return <Navigate to="/home" />;
        }
    } else {
        return <Navigate to="/login" />;
    }
};

export default AdminRouter;

