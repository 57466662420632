import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SignUp from 'login/SignUp';
import Login from './login/LoginUser';
import Home from './home/sidebar/Home/TechHome';
import PasswordChange from 'login/PasswordChange';

import DashboardInfo from 'home/sidebar/Dashboard/info';
import HealthStatistics from 'home/sidebar/Dashboard/HealthStatistics';
import ChatbotData from './home/sidebar/Dashboard/ChatbotData';
import HealthData from './home/sidebar/Dashboard/HealthData';

import ChatbotInfo from 'home/sidebar/Chatbot/info';
import Chatbot from './home/sidebar/Chatbot/Chatbot';
import CareMessanger from './home/sidebar/Chatbot/CareMessanger';
import ConsultChatbot from './home/sidebar/Chatbot/ConsultChatbot';

import AiChatbot from './home/sidebar/AIChatbot/AiChatbot';

import OCRInfo from 'home/sidebar/OCR/info';
import OCR from './home/sidebar/OCR/OCR';
import OCRresult from './home/sidebar/OCR/OCRresult';

import HeraInfo from 'home/sidebar/Her-a/info';
import Hera from './home/sidebar/Her-a/Her-a';
import Hera_statistics from 'home/sidebar/Her-a/Her-a_statistics';
import Hera_statistics_More from 'home/sidebar/Her-a/Her-a_StatisticsMore';

import './App.css';

import AdminHome from './admin/sidebar/AdminHome';
import AdminDashboard from './admin/sidebar/AdminDashboard';

import TechTrend from './techboard/TechTrend';
import TechTrendCreate from './techboard/component/TechTrendCreate';
import TechTrendShow from './techboard/component/TechTrendShow';
import TechTrendEdit from 'techboard/component/TechTrendEdit';

// import Test from 'test';

// import Router
import PrivateRoute from './router/PrivateRouter';
import AdminRouter from './router/AdminRouter';

// import antd
import { Button, ConfigProvider, Space } from 'antd';


function App() {
  let token = localStorage.getItem('JWT-token');

  return (
    
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Noto Sans KR', sans-serif",
          
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          {/* Home & Login */}
          <Route path="/" element={<PrivateRoute authenticated={token} component={<Home />} />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/Home" element={<PrivateRoute authenticated={token} component={<Home />} />}></Route>
          <Route path="/passwordChange" element={<PasswordChange />}></Route>
          <Route path="/signUp" element={<SignUp />}></Route>
          {/* <Route path="/Home" element={<Home />} /> */}

          {/* Dashboard */}
          <Route path="/dashboard/info" element={<PrivateRoute authenticated={token} component={<DashboardInfo />} />}></Route>
          <Route path="/dashboard/healthStatistics" element={<PrivateRoute authenticated={token} component={<HealthStatistics />} />}></Route>
          <Route path="/dashboard/chatbotData" element={<PrivateRoute authenticated={token} component={<ChatbotData />} />}></Route>
          <Route path="/dashboard/healthData" element={<PrivateRoute authenticated={token} component={<HealthData />} />}></Route>

          {/* Chatbot */}
          <Route path="/chatbot/info" element={<PrivateRoute authenticated={token} component={<ChatbotInfo />} />}></Route>
          <Route path="/chatbot" element={<PrivateRoute authenticated={token} component={<Chatbot />} />}></Route>
          <Route path="/chatbot/care" element={<PrivateRoute authenticated={token} component={<CareMessanger />} />}></Route>
          <Route path="/chatbot/consult" element={<PrivateRoute authenticated={token} component={<ConsultChatbot />} />}></Route>

          {/* AiChatbot */}
          <Route path="/aichatbot" element={<PrivateRoute authenticated={token} component={<AiChatbot />} />}></Route>

          {/* OCR */}
          <Route path="/img/info" element={<PrivateRoute authenticated={token} component={<OCRInfo />} />}></Route>
          <Route path="/img/OCR" element={<PrivateRoute authenticated={token} component={<OCR />} />}></Route>
          <Route path="/img/OCR/result" element={<PrivateRoute authenticated={token} component={<OCRresult />} />}></Route>

          {/* her-a */}
          <Route path="/NLP/info" element={<PrivateRoute authenticated={token} component={<HeraInfo />} />}></Route>
          <Route path="/NLP/her-a" element={<PrivateRoute authenticated={token} component={<Hera />} />}></Route>
          <Route path="/NLP/her-a/statistics" element={<PrivateRoute authenticated={token} component={<Hera_statistics />} />}></Route>
          <Route path="/NLP/her-a/statistics/:key" element={<PrivateRoute authenticated={token} component={<Hera_statistics_More />} />}></Route>

          {/* Admin */}
          <Route path="/admin/home" element={<AdminRouter authenticated={token} component={<AdminHome />} />}></Route>
          <Route path="/admin/dashboard" element={<AdminRouter authenticated={token} component={<AdminDashboard />} />}></Route>

          {/* Tech */}
          <Route path="/techboard" element={<PrivateRoute authenticated={token} component={<TechTrend />} />}></Route>
          <Route path="/techboard/write" element={<PrivateRoute authenticated={token} component={<TechTrendCreate />} />}></Route>
          <Route exact path="/techboard/show/:id" element={<PrivateRoute authenticated={token} component={<TechTrendShow />} />}></Route>
          <Route exact path="/techboard/edit/:id" element={<PrivateRoute authenticated={token} component={<TechTrendEdit />} />}></Route>

          {/* <Route exact path="/test" element={<PrivateRoute authenticated={token} component={<Test />} />}></Route> */}

        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;