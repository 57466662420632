import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '@mui/x-charts/BarChart';

// import apies
import { getExaminationName } from 'apies/status/fetcher';

export const PlotFindingKeywordsStats = () => {

  const startHealthDate = useSelector(state => state.startHealthDate);
  const endHealthDate = useSelector(state => state.endHealthDate);

  const [counts, setCounts] = useState([]);
  const [examinationNames, setExaminationNames ] = useState([]);

  useEffect(() => {
    if (startHealthDate && endHealthDate) {
      const formData = [startHealthDate, endHealthDate];

      getExaminationName(formData)
        .then(response => {
            setCounts(response.data.map(item => item.count));
            setExaminationNames(response.data.map(item => item.examination_Name));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [startHealthDate, endHealthDate]);

  return (
    <BarChart
    xAxis={[{
      scaleType: 'band',
      data: examinationNames,
      labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' },
      fontSize: 14,
      colorMap: {
        type:"ordinal",
        values: examinationNames,
        colors:['#a9d6e5','#89c2d9','#61a5c2','#468faf','#2c7da0','#2a6f97','#014f86','#01497c','#013a63'],
      }
    }]}
    series={[
      { data: counts,
        color: 'rgb(46,150,255)' ,
        labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' } },
    ]}
    width={630}
    height={300}
    barLabel="value"
  />
);

}

export default PlotFindingKeywordsStats;