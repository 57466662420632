import TechIndex from "Techindex";
import { useSelector, useDispatch } from 'react-redux';

// import antd
import { Radio, Layout, Input, Spin } from 'antd';
import { Card, Space } from 'antd';
import { Button, DatePicker as AntDatePicker, Modal } from 'antd';

// days
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { TitleItem } from "componets/Item";

const { RangePicker } = AntDatePicker;

export const Info = () => {
  return (
    <Layout.Content>
      {/* 타이틀 */}
      <TitleItem >
        <div className="title-area" >
          <p className="main-title" style={{ fontSize: '17px' }} >
            통계(대시보드)
          </p>
        </div>
      </TitleItem>
      <TitleItem style={{ marginTop: '1%' }}>
        <div className="tech-area">
          <div style={{ textAlign: 'left', fontSize:'15px'  }}>
            <span style={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>대</span>
            <span style={{ fontWeight: '470', color: '#000000' }}>시보드는 다양한 종류의 데이터를 수집하고 분석하여 이를 시각적으로 표현합니다.</span>
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: 'flex',
                marginTop: '1%',
              }}
            >
              <Card title="Ⅰ날짜 선택하기" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%' , color:'#000000', fontSize:'15px'  }} >
                <div>
                  <p>⦁ 미리 첨부된 데이터의 날짜 기준으로 대시보드를 테스트할 수 있습니다.</p>
                  <p>
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={{
                          format: 'YYYY-MM-DD',
                          type: 'mask',
                        }}
                        defaultValue={dayjs('2024-03-01', 'YYYY-MM-DD')}
                        minDate={dayjs('2024-03-01', 'YYYY-MM-DD')}
                        maxDate={dayjs('2024-04-05', 'YYYY-MM-DD')}
                      />
                    </Space>
                  </p>
                  <Button type="primary">테스트 시작하기</Button>
                  <p>⦁ 첨부된 데이터의 시작과 끝 날짜 기간으로 조회할 수 있습니다.</p>
                  <div style={{ float: 'left', marginRight: '1rem', marginBottom: '0.5rem' }}>
                    <img src="/files/dashboard/datepiker.png" alt="date" style={{ width: '85%' }} />
                  </div>
                </div>
              </Card>
              <Card title="Ⅱ그래프 종류 설명" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%' , color:'#000000', fontSize:'15px'  }} >
                <p style={{fontWeight:'bold'}}>ⅰ파이차트</p>
                <p>⦁ 원형그래프로, 전체 중 특정 부분이 차지하는 비율을 나타내며 점유율이나 분포를 파악할 때 사용됩니다.</p>
                <div style={{ float: 'left', marginRight: '1rem', marginBottom: '0.5rem', width:'100%'}}>
                    <img src="/files/dashboard/piechart.png" alt="pie" style={{ width: '70%' }} />
                </div>
                <p style={{fontWeight:'bold'}}>ⅱ선 그래프</p>
                <p style={{marginBottom:'0px'}}>⦁ 시간에 따른 데이터의 변화를 나타내는데 적합하며, 추세를 파악하는 데에도 유용합니다.</p>
                <div style={{ float: 'left', marginRight: '1rem', marginBottom: '0.5rem', width:'100%'}}>
                    <img src="/files/dashboard/linechart.png" alt="line" style={{ width: '40%' }} />
                </div>
                <p style={{fontWeight:'bold'}}>ⅲ 막대 그래프</p>
                <p>⦁ 비연속적인 항목의 크기를 막대의 길이로 나타내는 그래프입니다.</p>
                <div style={{ float: 'left', marginRight: '1rem', marginBottom: '0.5rem', width:'100%'}}>
                    <img src={`${process.env.PUBLIC_URL}/files/dashboard/barchart.png`} alt="bar" style={{ width: '40%' }} />
                </div>
                <p style={{fontWeight:'bold'}}>ⅳ 도넛 차트</p>
                <p>⦁ 전체를 100%으로 놓고, 그 중에서 무엇이 얼마나 많은 비율을 차지하는지 분석하는 데 유용합니다.</p>
                <div style={{ float: 'left', marginRight: '1rem', marginBottom: '0.5rem', width:'100%'}}>
                    <img src="/files/dashboard/donutchart.jpg" alt="donut" style={{ width: '40%' }} />
                </div>
                <p style={{fontWeight:'bold'}}>ⅴ 워드클라우드</p>
                <p>⦁ 텍스트 데이터를 분석하여 단어의 빈도 수나 중요도를 시각적으로 표현합니다.</p>
                <div style={{ float: 'left', marginRight: '1rem', marginBottom: '0.5rem', width:'100%'}}>
                    <img src="/files/dashboard/wordcloud.png" alt="word" style={{ width: '30%' , border: '2px solid #ccc', borderRadius: '5px' }} />
                </div>
                
              </Card>
            </Space>
          </div>
        </div>

      </TitleItem>
    </Layout.Content>
  )
}

const DashboardInfo = () => {
  return (
    <TechIndex>
      <Info />
    </TechIndex>
  );
};

export default DashboardInfo;
