import axios from "axios";

const default_url = process.env.REACT_APP_API_URL;
const ai_url = 'https://adm.aimmed.com';

const urls = {
    //DEFAULT URL 
    aiapi: default_url,

    showTestRateURL: `${default_url}/api/home/allTest/show`,
    showWeeklyUserCountURL:`${default_url}/api/home/weeklyUser/show`,
    saveTodayTestRateURL: `${default_url}/api/home/todayTest/save`,
    showTodayTestRateURL: `${default_url}/api/home/todayTest`,
};

const token = localStorage.getItem('JWT-token');

export const getTestRate = () => axios({
    method: 'get',
    url: urls.showTestRateURL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const getWeeklyUserCount = () => axios({
    method: 'get',
    url: urls.showWeeklyUserCountURL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const getTodayTestRate =(formData) => axios({
    method: 'post',
    url: urls.saveTodayTestRateURL,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const showTodayTestRate = (date) => axios({
    method: 'get',
    url:`${urls.showTodayTestRateURL}/${date}`,
    headers:{
        "Content-Type": "application/json",
        "Authorization": token
    }
})