import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// import antd
import { Layout, Input, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

import { FcCheckmark, FcCancel } from "react-icons/fc";

// import material 
import Button from '@mui/material/Button';

import AWS from 'aws-sdk';

// import apies
import { showTrendPost, deleteTrendPost } from 'apies/status/postFetcher';
import { createComment } from 'apies/status/commentFetcher';
import { deleteComment } from 'apies/status/commentFetcher';

import TechIndex from '../../../Techindex';

import { TitleItem } from 'componets/Item';

const { Content } = Layout;
const { confirm } = Modal;

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

export const TechTrendSub = () => {

    const { id } = useParams();

    const [index, setIndex] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');
    const [user, setUser] = useState('');
    const [file, setFile] = useState('');
    const [comments, setComments] = useState([]);

    useEffect(() => {
        showTrendPost(id)
            .then(response => {
                console.log(response.data[1][0])
                const postsData = response.data[0];
                if (!Array.isArray(postsData)) {
                    throw new Error('데이터 형식 불일치');
                }

                const newData = postsData.map(item => ({
                    index: item.id.toString(),
                    title: item.title,
                    content: item.content,
                    status: item.status === 1 ? '공개' : '비공개',
                    date: item.date,
                    user: item.user || '사용자 미정'
                }));

                setIndex(newData[0]['index']);
                setTitle(newData[0]['title']);
                setContent(newData[0]['content']);
                setStatus(newData[0]['status']);
                setDate(newData[0]['date']);
                setUser(newData[0]['user']);
                setFile(response.data[2]);

                const commentsData = response.data[1] || [];
                setComments(commentsData.map(comment => ({
                    content: comment.content,
                    user: comment.user,
                    date: comment.date,
                    id: comment.id
                })));

            })

            .catch(error => {
                console.error('데이터없음', error);
                Modal.error({
                    title: '삭제된 포스트 입니다.',
                    onOk() {
                        console.log('OK');
                        window.location.href = '/techboard'
                    },
                });
            });

    }, [id]);

    const bucketName = 'aimmed-mobileai-rdl';
    const fileKey = 'rdl-post-files/' + file;

    function downloadImage(bucketName, fileKey) {
        const params = {
            Bucket: bucketName,
            Key: fileKey
        };

        s3.getObject(params, (err, data) => {
            if (err) {
                console.error(err);
                alert('파일 다운로드 실패');
            } else {
                const blob = new Blob([data.Body], { type: data.ContentType });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileKey);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(url);
            }
        });
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [password, setPassword] = useState('');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        deleteTrendPost(id)
            .then(response => {
                Modal.success({
                    content: '포스트가 삭제되었습니다.',
                    okText: '확인',
                    onOk() {
                        console.log('OK');
                        window.location.href = '/techBoard'
                    },
                });
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let userName = localStorage.getItem('userName');

    const onClickEvent = () => {
        window.location.href = '/techBoard';
    }

    const onEditEvent = (id) => {
        window.location.href = '/techBoard/edit/' + id;
    }

    const [commentValue, setCommentValue] = useState('');

    const onCommentChange = (e) => {
        setCommentValue(e.target.value);
    }

    const onCommentCreate = () => {
        const formData = new FormData();
        const today = new Date();

        formData.append('date', today.toISOString().split('T')[0])
        formData.append('content', commentValue);
        formData.append('user', userName);

        createComment(id, formData)
            .then(response =>
                Modal.success({
                    content: '댓글이 작성되었습니다.',

                    onOk() {
                        console.log('OK');
                        window.location.reload();
                    },

                })
            );
    }

    const onCommentDelete = (CommentId) => {
        if (CommentId != null) {
            deleteComment(CommentId)
                .then(response =>
                    Modal.success({
                        content: '댓글이 삭제되었습니다.',
    
                        onOk() {
                            console.log('OK');
                            window.location.reload();
                        },
    
                    })
                 )
        }
    }

    return (
        <Content>
            <TitleItem>
                <div className="title-area">
                    <div className="main-title">
                        기술 트렌드
                    </div>
                    <p></p>
                    <div className="sub-title">
                        기술 동향 게시판 입니다.
                    </div>
                </div>
            </TitleItem>
            <div className="tech-area" style={{ display: 'grid', fontSize: '12px', width: '100%' }}>
                <div>
                    <TitleItem style={{ marginTop: '15px' }}>
                        <div className='search-area' style={{ display: 'grid', width: '100%', gridTemplateColumns: '20% 80%' }}>
                            <div className='search-menu'>제목</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                {title}
                            </div>
                            <div className='search-menu'>작성자</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                {user}
                            </div>
                            <div className='search-menu'>작성일</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                {date}
                            </div>
                            <div className='search-menu'>공개여부</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                {status}
                            </div>
                            <div className='search-menu' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >내용</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <div style={{ height: '160px' }}>
                                    {content}
                                </div>
                            </div>
                            <div className='search-menu'>파일첨부</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <div>
                                    <div style={{ textDecoration: 'underline' }} onClick={() => downloadImage(bucketName, fileKey)}>
                                        {file}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ alignItems: 'center', marginTop: '1%' }}>
                            <div style={{ textAlign: 'left' }}>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button onClick={onClickEvent} variant="outlined" style={{ fontFamily: '"Noto Sans KR", sans-serif', marginRight: '10px' }}>목록으로 이동</Button>
                                <Button variant="contained" onClick={() => onEditEvent(id)} style={{ fontFamily: '"Noto Sans KR", sans-serif', marginRight: '10px' }}>수정</Button>
                                <Button variant="outlined" color="error" onClick={showModal} style={{ fontFamily: '"Noto Sans KR", sans-serif' }}>삭제</Button>
                            </div>
                            <Modal
                                style={{ textAlign: 'center' }}
                                title="삭제하시겠습니까?"
                                open={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="삭제"
                                cancelText="취소"
                                okButtonProps={{ disabled: password !== '1234' }}
                                icon={<ExclamationCircleFilled />}
                            >
                                <p>삭제하려면 비밀번호를 입력해주세요.</p>
                                <Input.Password
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    placeholder="비밀번호 입력"
                                />
                            </Modal>
                        </div>
                        <h3 style={{ textAlign: 'left', marginLeft: '1%', marginTop: '0%' }}>댓글</h3>
                        <div className='search-area' style={{ display: 'grid', width: '100%', gridTemplateColumns: '20% 70% 10%' }}>
                            {comments.map((comment, index) => (
                                <React.Fragment key={index}>
                                    <div className='search-menu' style={{ gridColumn: '1 / 2', alignItems: 'center', justifyContent: 'center' }}>{comment.user}</div>
                                    <div className='menu-item' style={{ textAlign: 'left', gridColumn: '2 / 3' }}>
                                        {comment.content}
                                    </div>
                                    <div className='menu-item' style={{ paddingRight: '25px', fontSize: '12px' }}>
                                        {comment.date}
                                        <span><Button
                                             onClick={() => onCommentDelete(comment.id)} 
                                            style={{
                                                marginLeft: '15%', fontWeight: 'bold', fontSize: '12px', fontFamily: '"Noto Sans KR", sans-serif',
                                                color: '#000000', marginLeft: '2%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                fontWeight: 'bold'
                                            }}>
                                            삭제<FcCancel /></Button></span>
                                    </div>
                                </React.Fragment>))}
                        </div>
                        <TitleItem style={{ marginTop: '1%', marginBottom: '2%', backgroundColor: 'rgb(236, 241, 247)' }}>
                            <div className='search-area' style={{ display: 'grid', width: '100%', gridTemplateColumns: '90% 10%' }}>
                                <TextArea value={commentValue} onChange={onCommentChange} placeholder='댓글을 입력해 주세요.' style={{ height: '90%' }}></TextArea>
                                <Button onClick={onCommentCreate} style={{ fontFamily: '"Noto Sans KR", sans-serif', color: '#000000', marginLeft: '2%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>등록<FcCheckmark /></Button>
                            </div>
                        </TitleItem>
                    </TitleItem>
                </div>
            </div>
        </Content>
    )
}

const TechTrendShow = () => {
    return (
        <TechIndex>
            <TechTrendSub />
        </TechIndex>
    );
}

export default TechTrendShow;
