import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '@mui/x-charts/BarChart';

// import apies
import { getInspectionRatio } from 'apies/status/fetcher';

export const CurrentYearInspectionRate = () => {
  const startHealthDate = useSelector(state => state.startHealthDate);
  const endHealthDate = useSelector(state => state.endHealthDate);

  const [inspection, setInspection] = useState([null]);
  const [counts, setCounts] = useState([null]);

  useEffect(() => {
    if (startHealthDate && endHealthDate) {
      const formData = [startHealthDate, endHealthDate];

      getInspectionRatio(formData)
        .then(response => {
          setInspection(response.data.map(item => item.inspection));
          setCounts(response.data.map(item => item.count));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [startHealthDate, endHealthDate]);

  if (counts.length < 2 || counts[0] == null || counts[1] == null) {
    return <div>Loading...</div>; 
  }

  let total = counts[0] + counts[1];
  let startYears = startHealthDate.substring(0, 4);
  let endYears = endHealthDate.substring(0, 4);

  let inspectionRate = (counts[0] / total).toFixed(2);

  // 2023년 조회 - 2024년 조회 따로 해야되는거 나중에 처리 필요
  return (
    <BarChart
      xAxis={[{
        scaleType: 'band',
        data: [startYears, endYears],
        labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' },
        fontSize: 14,
      }]}
      series={[
        {
          data: [inspectionRate],
          label: '수검률',
          color: '#468faf',
          labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' }
        },
      ]}
      // width={240}
      height={300}
       barLabel="value"
    />
  );
}

export default CurrentYearInspectionRate;
