import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Chatbot from 'react-chatbot-kit';
import config from "./config";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import 'react-chatbot-kit/build/main.css';
import { ThemeProvider } from 'styled-components';
import { Spin } from 'antd';

import { TitleItem } from "componets/Item";

export const Chatting = () => {
  // 챗봇 생성 여부 상태값
  const isChatBotCreating = useSelector(state => state.isChatBotCreating);
  console.log('상태업데이트', isChatBotCreating);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // 컴포넌트가 마운트될 때마다 상태를 변경
    setIsMounted(prev => !prev);
  }, []);

  if (isMounted) {
    if (isChatBotCreating === true) {
      return (
        <TitleItem style={{height:'900px'}}>
        <div style={{ width: '375px', marginTop:'5%',marginLeft: 'auto', marginRight: 'auto', border: '5px solid rgb(55, 71, 104)', borderRadius: '36px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', position: 'relative' }}>
          <div style={{ height: '40px', width: '100%', borderTopLeftRadius: '36px', borderTopRightRadius: '36px', position: 'absolute', top: '0', left: '0', zIndex: '1' }}></div>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            placeholderText='메세지를 입력해주세요.'
          />
        </div>
        </TitleItem>
      );
    } else {
      // 챗봇 생성 로딩 화면
      return (
        <TitleItem>
        <div style={{ height: '900px', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
          <h3>챗봇 생성 중입니다.</h3>
          <p>시간이 다소 소요될 수 있습니다.</p>
          <Spin size="large" />
        </div>
        </TitleItem>
      );
    }
  } else {
    return (
      <div>
        <h3>챗봇 생성 상태를 확인 중입니다.</h3>
      </div>
    );
  }
};

export default Chatting;
