import { useState } from "react";
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import api
import { postAnswer } from "../../../../../apies/status/fetcher";

// import action
import { setChatAnswer } from "../../../../../action/action";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  //챗봇의 답변
  const chatbotAnswer = useSelector(state => state.chatAnswer);
  //챗봇의 chainNum import 
  const chainNum = useSelector(state => state.chainNum);
  console.log('chainNum', chainNum)
  const dispatch = useDispatch();

  const UserQuestion = async (message) => {

    let formData = new FormData();
    formData.append('input', message);
    formData.append('chain_num', chainNum);

    postAnswer(formData)
      .then(response => {
        let chatAnswer = response.data.response;
        dispatch(setChatAnswer(chatAnswer));
        console.log('응답', chatAnswer);
        console.log(chatAnswer);

        const botMessage = createChatBotMessage(chatAnswer);

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      });


  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            UserQuestion,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
