import { React, useState, useEffect } from 'react'
import { PieChart, pieArcLabelClasses, } from '@mui/x-charts/PieChart';
import { useSelector } from 'react-redux';

// import apies
import { getHealthFindingsCount } from '../../../../../../apies/status/fetcher';

export const HealthFindingsOverviewChart = () => {

    // pie chart color
    const palette =  ['#014f86', '#468faf', '#a9d6e5'];

    const startHealthDate = useSelector(state => state.startHealthDate);
    const endHealthDate = useSelector(state => state.endHealthDate);

    const [findingsResultCount, setFindingsResultCount] = useState([null, null]);
    const findingsResult = ['정상', '중증유소견', '유소견']

    useEffect(() => {
        if (startHealthDate && endHealthDate) {
            let formData = [startHealthDate, endHealthDate];

            getHealthFindingsCount(formData)
                .then(response => {
                    setFindingsResultCount(response.data);
                })


        }
    }, [startHealthDate, endHealthDate]);

    const data = findingsResult.map((findings, index) => ({
        id: index,
        value: findingsResultCount[index],
        label: findings
    }));

    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    return (
        <div style={{ height: '600px', justifyContent: 'center', alignContent: 'center', textAlign: 'center', borderRadius: '4%' }}>
            <PieChart
                colors={palette}
                slotProps={{
                    legend: {
                        labelStyle: {
                            fontFamily: '"Noto Sans KR", sans-serif'
                        },
                        position: {
                            vertical: 'middle',
                            horizontal: 'right',
                        },
                        itemMarkWidth: 20,
                        itemMarkHeight: 2,
                        markGap: 5,
                        itemGap: 10,
                    }
                }}
                series={[
                    {
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, paddingAngle: '10px' },
                        arcLabel: getArcLabel,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        // fill: 'white',
                        fontSize: 18,
                        fontWeight:'bold',
                        fontFamily: '"Noto Sans KR", sans-serif',
                    },
                }}
                height={270}
            />
            <p></p>
        </div>
    )
}

export default HealthFindingsOverviewChart;