import axios from "axios";

const default_url = process.env.REACT_APP_API_URL;
const ai_url = 'https://adm.aimmed.com';

const urls = {
    //DEFAULT URL 
    aiapi: default_url,

    //HOME - LOGIN USER COUNT
    getusercount: `${default_url}/api/LoginUserCount`,

    //LOGOUT
    logoutURL: `${default_url}/api/logout`,

    //DASHBOARD - USER COUNT
    getDateUserCount: `${default_url}/api/date/userCount`,
    //DASHBOARD - COMPNAY COUNT
    getCompanyCount: `${default_url}/api/date/company`,
    //DASHBOARD - CONTENTS COUNT
    getContentsCount: `${default_url}/api/date/contents`,
    //DASHBOARD - AVERAGE TIME
    getAverageTime: `${default_url}/api/date/averageTime`,

    //DASHBOARD - HEALTH USER COUNT
    getDateHealthUserCount: `${default_url}/api/date/health/userCount`,
    //DASHBOARD - HEALTH FINDINGS OVERVIEW
    getHealthFindings: `${default_url}/api/date/health/findings`,
    //DASHBOARD - HEALTH CUSTOMER BY AGE AND SEX
    getCustomerByAgeAndSex: `${default_url}/api/date/health/customers-by-age-and-sex`,
    //DASHBOARD - HEALTH CUSTOMER BY AGE
    getCustomerByAge: `${default_url}/api/date/health/customers-by-age`,
    //DASHBOARD - HEALTH INSPCETION RATIO
    getInspectionRatio: `${default_url}/api/date/health/inspectionRatios`,
    //DASHBOARD - HEALTH EXAMINATION NAME COUNT
    getExaminationRate : `${default_url}/api/date/health/examinationNames`,

    //AI CHATBOT - S3 SAVE PROMPT
    s3Input: `${default_url}/api/inputS3File`,

    //AI CHATBOT - CREATE CHAT, ANSWER
    createChat: `${ai_url}/chat/create`,
    createAnswer: `${ai_url}/chat/dialog`,

    //OCR IMAGES 
    convertImg: `${default_url}/api/convertImg`,
    createAnswerImage: `${ai_url}/image/ocr`,
    createAnswerString: `${ai_url}/image/ocr/texts`,

    //HER-A SUMMARY AND CASSIFY OPINION
    summaryTotext: `${ai_url}/text/summary`
};

// Login
const token = localStorage.getItem('JWT-token');

// statusCode 확인
export const getStatusCode = () => axios(urls.aiapi).then(response => {
    console.log(response.data);
})
    .catch(error => {
        console.error('Error fetching data:', error);
    });


export const getLoginUserCount = () => axios({
    method: 'post',
    url: urls.getusercount,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

// logout
export const logoutApi = () => axios({
    method: 'get',
    url:urls.logoutURL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

//DashBoard - Chatbot

//챗봇 데이터 - UserCount 추출
export const getDateUserCount = (formData) => axios({
    method: 'post',
    url: urls.getDateUserCount,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

//챗봇 데이터 - CompanyCount 추출
export const getCompanyCount = (formData) => axios({
    method: 'post',
    url: urls.getCompanyCount,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

//챗봇 데이터 - ContentsCount(가장 많이 사용한 키워드) 추출
export const getContentsCount = (formData) => axios({
    method: 'post',
    url: urls.getContentsCount,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

//챗봇 잔류 시간 
export const getAverageTime = (formData) => axios({
    method: 'post',
    url: urls.getAverageTime,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }

})

// DashBoard - Health 유저 카운트
export const getDateHealthUserCount = (formData) => axios({
    method: 'post',
    url: urls.getDateHealthUserCount,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

// DashBoard - Health 소견 결과 분포 (정상/중증유소견/유소견)
export const getHealthFindingsCount = (formData) => axios({
    method: 'post',
    url: urls.getHealthFindings,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

// DashBoard - Health 성별/연령대 별 고객 현황(20대 - n명/ 남자,여자 비율)
export const getCustomerByAgeAndSex = (formData) => axios({
    method: 'post',
    url: urls.getCustomerByAgeAndSex,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }

})

// DashBoard - Health 연령대별 수검 인원 (20대 - N명/ 30대 - N명)
export const getCustomerByAge = (formData) => axios({
    method: 'post',
    url: urls.getCustomerByAge,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

// DashBoard - Health 당해년도 수감률 ( 2023년 - N% )
export const getInspectionRatio = (formData) => axios({
    method: 'post',
    url: urls.getInspectionRatio,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

// DashBoard - Health 상위 유소견 키워드 현황 
export const getExaminationName = (formData) => axios({
    method: 'post',
    url: urls.getExaminationRate,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

//AI

// PDF 파일 S3에 저장
export const postPDF = (formData) => axios({
    method: 'post',
    url: urls.s3Input,
    data: formData,
    headers: {
        "Content-Type": 'multipart/form-data',
    }
})

// 챗봇 생성
export const postChat = (ResponseData) => axios({
    method: 'post',
    url: urls.createChat,
    data: ResponseData,
    headers: {
        "Content-Type": "application/json"
    }
})

// 챗봇의 답변 생성
export const postAnswer = (formData) => axios({
    method: 'post',
    url: urls.createAnswer,
    data: formData,
    headers: {
        "Content-Type": "application/json",
    }
})

// OCR
export const postImages = (ImageForm) => axios({
    method: 'post',
    url: urls.convertImg,
    data: ImageForm,
    headers: {
        "Content-Type": 'multipart/form-data',
    }
})

export const postGetImages = async (imagesResponseData) => {
    try {
        const response = await axios({
            method: 'post',
            url: urls.createAnswerImage,
            data: imagesResponseData,
            headers: {
                "Content-Type": 'application/json',
            },
            responseType: 'blob'  // 바이너리 데이터로 응답 받기
        });

        // 응답 데이터를 로컬 스토리지에 저장
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                const base64data = reader.result;
                localStorage.setItem('ocrImg', base64data);
                resolve(base64data);  // base64data를 반환
            };
            reader.onerror = reject;
        });
    } catch (error) {
        console.error('이미지 불러오기 실패', error);
        throw error;
    }
}

export const postGetImageToText = (imagesResponseData) => axios({
    method: 'post',
    url: urls.createAnswerString,
    data: imagesResponseData,
    headers: {
        "Content-Type": "application/json",
    }
})

export const summaryText = (targetText) => axios({
    method: 'post',
    url: urls.summaryTotext,
    data: targetText,
    headers: {
        "Content-Type": "application/json",
    }
})





