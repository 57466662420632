import { React, useState,useEffect  } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { useSelector } from 'react-redux';

export const WeeklyUserCount = () => {

    const inputCounts = useSelector(state => state.inputCounts);

    const [week, setWeek] = useState([null, null]);
    const [weekCount, setWeekCount] = useState([null, null]);

    

    useEffect(() => {
        if (inputCounts) {
            setWeek(inputCounts[1]);
            setWeekCount(inputCounts[0].map(datas => `${datas}주차` ));
        } else {
            console.log('inputCounts is Null');
        }
    }, [inputCounts]);

    return (
        <div style={{ fontFamily: '"Noto Sans KR", sans-serif', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <BarChart
                series={[{ data: week }]}
                height={200}
                xAxis={[{
                    data: weekCount,
                    scaleType: 'band',
                    colorMap: {
                        type: 'ordinal',
                        colors: ['#a9d6e5', '#2a6f97', '#013a63', '#468faf', '#014f86', '#01497c'],
                    },
                }]}
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
        </div>
    );
}