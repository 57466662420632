// 리듀서(Reducer) 정의: 액션에 따라 상태를 어떻게 변경할지를 정의하는 리듀서를 작성
const initialState = {
  // chatDataCount: 0,
  // healthDataCount: 0,
  // chatCount: 0,
  // AIChatCount: 0,
  // OCRCount: 0,
  // HeraCount:0,

  isChatBotCreating: false,
  chainNum: 0,
  userQuestion: '',
  chatAnswer: '',
  userImage: null,
  ImageCreating: false,
  infoUserName: '',
  TextIsCreating: 'TEST',
  TextSummaryResult: '',
  ExamClass: '',
  startDate: null,
  endDate: null,
  inputCounts: null,
  startHealthDate: null,
  endHealthDate: null,
  password: null,
  PostLenght: 0,
  chatDatas: 0,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'SET_CHAT_DATA_COUNTS':
    //   return { ...state, chatDataCount: action.payload}
    // case 'SET_HEALTH_DATA_COUNTS':
    //   return { ...state, healthDataCount: action.payload}
    // case 'SET_CHAT_COUNTS':
    //   return { ...state, chatCount: action.payload};
    // case 'SET_AI_CHAT_COUNTS':
    //   return { ...state, AIChatCount: action.payload};
    // case 'SET_OCR_COUNTS':
    //   return { ...state, OCRCount: action.payload};
    // case 'SET_HERA_COUNTS':
    //   return { ...state, HerACount: action.payload}
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_IS_CHATBOT_CREATING':
      return { ...state, isChatBotCreating: action.payload };
    case 'SET_CHAIN_NUM':
      return { ...state, chainNum: action.payload };
    case 'SET_USER_QUESTION':
      return { ...state, userQuestion: action.payload };
    case 'SET_Chat_ANSWER':
      return { ...state, chatAnswer: action.payload };
    case 'SET_INSERT_IMAGES':
      return { ...state, userImage: action.payload };
    case 'SET_IS_IMAGE_CREATING':
      return { ...state, ImageCreating: action.payload };
    case 'INFO_USER_NAME':
      return { ...state, infoUserName: action.payload };
    case 'SET_IS_TEXTSUMMARY_CREATING':
      return { ...state, TextIsCreating: action.payload };
    case 'SET_TEXT_SUMMARY_RESULT':
      return { ...state, TextSummaryResult: action.payload };
    case 'SET_EXAM_CLASS':
      return { ...state, ExamClass: action.payload };
    case 'SET_LOOK_UP_CHATBOT_DATAS':
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
    case 'SET_WEEK_USER_COUNT':
      return { ...state, inputCounts: action.payload };
    case 'SET_LOOK_UP_HEALTH_DATAS':
      return {
        ...state,
        startHealthDate: action.payload.startHealthDate,
        endHealthDate: action.payload.endHealthDate
      };
    case 'SET_TECH_POST_LENGTH':
      return { ...state, PostLenght: action.payload };
    case 'SET_CHAT_DATAS':
      return { ...state, chatDatas: action.payload};
    default:
      return state;

  }
};

export default rootReducer;


