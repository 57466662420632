import TechIndex from "../../../../Techindex";
import { useState } from 'react';
import { Button, Layout } from 'antd';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TitleItem } from "componets/Item";

const HealthStatisticsDashboard = () => {
    const [dashboardStatus, setDashboardStatus] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(true);
    const iframeSrc = "https://aimmed-rdl.shinyapps.io/exam_dashboard/";

    const onSummit = () => {
        setDashboardStatus(true);

        // HealthStatistics 테스트 이용률 집계
        let healthStatisticsCount = localStorage.getItem('HealthStatisticsCount');

        if (healthStatisticsCount === null || healthStatisticsCount === "null") {
            healthStatisticsCount = 0;
        } else {
            healthStatisticsCount = parseInt(healthStatisticsCount);
        }

        healthStatisticsCount += 1;
        localStorage.setItem('HealthStatisticsCount', JSON.stringify(healthStatisticsCount));

        // HealthStatisticsData 일일 이용횟수 집계
        const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD

        const dailyStoredData = localStorage.getItem(`HealthStatisticsCounts-${today}`);
        const dailyDataCount = parseInt(dailyStoredData, 10) || 0;

        const dailyCounts = dailyDataCount + 1;

        // const dailyHealthStatisticsCount = {
        //     count: dailyCounts,
        //     timeStamp: new Date().toISOString()
        // };
        localStorage.setItem(`HealthStatisticsCounts-${today}`,dailyCounts);
    }

    const handleIframeLoad = () => {
        setIframeLoading(false);
    };

    if (!dashboardStatus) {
        return (
            <Layout.Content>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={15}>
                            <TitleItem>
                                <div className="title-area">
                                    <div className="main-title">
                                        건강통계 데이터
                                    </div>
                                    <p></p>
                                    <div className="sub-title">
                                        에임메드 건강 통계 데이터를 대시보드로 표현한 웹 뷰 입니다.
                                    </div>
                                </div>
                            </TitleItem>
                        </Grid>
                        <Grid item xs={20}>
                            <TitleItem>
                                <div className="tech-area" style={{ height: '800px', paddingTop: '15%' }}>
                                    <div>
                                        <h3>건강 통계 대시보드</h3>
                                        에임메드 전사 건강 통계 데이터를 시각화한 대시보드 페이지입니다.
                                        <p></p>
                                        [테스트 시작하기]버튼을 선택하면 해당 화면으로 이동합니다.
                                        <p></p>
                                    </div>
                                    <p></p>
                                    <Button type="primary" onClick={onSummit}>테스트 시작하기</Button>
                                </div>
                            </TitleItem>
                        </Grid>
                    </Grid>
                </Box>
            </Layout.Content>
        );
    } else {
        return (
            <Layout.Content>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={15}>
                            <TitleItem>
                                <div className="title-area">
                                    <div className="main-title">
                                        건강통계 데이터
                                    </div>
                                    <p></p>
                                    <div className="sub-title">
                                        에임메드 건강 통계 데이터를 대시보드로 표현한 웹 뷰 입니다.
                                    </div>
                                </div>
                            </TitleItem>
                        </Grid>
                        <Grid item xs={20}>
                            <TitleItem>
                                <div className="tech-area" style={{ height: '900px' }}>
                                    {iframeLoading && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <div class="container">
                                                <div class="box">
                                                    <div class="clock"></div>
                                                    <p>please Wait...</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                            <iframe
                                                src={iframeSrc}
                                                width="100%"
                                                height="100%"
                                                title="Chatbot_exam"
                                                style={{ border: 'none', borderRadius: '0 0 36px 36px' }}
                                                onLoad={handleIframeLoad}
                                            />
                                            <p></p>
                                        </div>
                            </TitleItem>
                        </Grid>
                    </Grid>
                </Box>
            </Layout.Content>
        );
    }
};

const HealthStatistics = () => {
    return (
        <TechIndex>
            <HealthStatisticsDashboard />
        </TechIndex>
    );
};

export default HealthStatistics;
