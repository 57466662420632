import React from 'react';
import { useState, useEffect } from 'react';
import TechIndex from '../../../Techindex';

import { Card, Col, Row } from 'antd';
import { Layout } from 'antd';
import { Spin } from 'antd';

// import components
import { TechCount } from './components/techCount';
import { TechUtilizationRate } from './components/techUtilizationRate';
import { TechWeekly } from './components/techWeekly';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import axios from 'axios';

// import apies
import { getWeeklyUserCount } from 'apies/status/homeFetcher';

const TitleItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontFamily: '"Noto Sans KR", sans-serif',
    borderRadius: '10px',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'

}));

const CustomContent = () => {

    // const today = new Date().toISOString().slice(0, 10);

    // localStorage.removeItem(`ChatCounts-${today}`);
    // localStorage.removeItem(`OCRCounts-${today}`);
    // localStorage.removeItem(`ChatDataCounts-${today}`);
    // localStorage.removeItem(`HealthStatisticsCounts-${today}`);
    // localStorage.removeItem(`HealthDataCounts-${today}`);
    // localStorage.removeItem(`AIChatCounts-${today}`);
    // localStorage.removeItem(`ChatDataCounts-${today}`);


    const [dailyDatas, setDailyDatas] = useState([]);
    const [dataStatus, setDataStatus] = useState(false);

    useEffect(() => {
        getWeeklyUserCount()
        .then(response=>{
            setDailyDatas(response.data[0]['count'])
            setDataStatus(true);
        })
        .catch(error=>{
            console.log(error)
            setDataStatus(true);
        })
    },[])

    
    return (
        <Layout.Content>
            <TitleItem
            >
                {/* 타이틀 */}

                <div className="title-area" style={{ marginTop: 'None' }}>
                    <div className="main-title">
                        Hello World! 에임메드 기술 라이브러리입니다.
                    </div>
                    <p></p>
                    <div className="sub-title">
                        좌측 메뉴를 통해 현재 구현 가능한 기술을 테스트할 수 있습니다.
                    </div>
                </div></TitleItem>
            {/* 기술영역 */}

            <div className="tech-area" style={{ display: 'grid', gridTemplateColumns: '25% 75%', paddingTop: '20px' }}>
                <div class="container">
                    <a class="card1">
                        <h3 >오늘 방문자 수 </h3>
                        <p class="small" style={{fontSize:'22px'}}>
                            {dataStatus ? `${dailyDatas}명`: <Spin size="small"/>}
                            </p>
                    </a>
                </div>
                <TechCount></TechCount>
            </div>
            <div className="tech-area" style={{ display: 'grid', gridTemplateColumns: '60% 0.1% 39.9%', paddingTop: '20px' }}>
                <TitleItem>
                    <div>
                        <div style={{ fontWeight: 'bold', fontFamily: '"Noto Sans KR", sans-serif', textAlign: 'left', fontWeight: 'bold', fontSize: '13px', marginLeft: '5px', marginTop: '5px' }}>
                        </div>
                        <TechUtilizationRate></TechUtilizationRate>
                    </div>
                </TitleItem>
                <div></div>
                <TechWeekly></TechWeekly>
            </div>
            <p></p>
        </Layout.Content>
    );
};


const Home = () => {
    return (
        <TechIndex>
            <CustomContent />
        </TechIndex>

    );
};

export default Home;