import { React, useState, useEffect } from 'react'

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// import apies
import { getTrendPostTables } from 'apies/status/postFetcher';

export const TechCount = () => {

  useEffect(() => {
    getTrendPostTables()
      .then(response => {

        // 트렌드 게시판의 글 개수를 localStorage 저장
        localStorage.setItem('post_length', response.data.length)

      })
      .catch(error => {
        console.error('Error', error);
      });
  }, []);

  const postLength = localStorage.getItem('post_length');

  return (
    <div>
      <div className="container" style={{ paddingLeft: '5px', display: 'flex', justifyContent: 'space-between' }}>
        <a className="card1" style={{ width: '320px' }}>
          <h3>테스트 가능한 기술</h3>
          <p className="small" style={{fontSize:'22px'}}>7개</p>
        </a>
        <a className="card1" style={{ width: '320px' }}>
          <h3>기술 트렌드</h3>
          <p className="small"style={{fontSize:'22px'}}>(예정)</p>
        </a>
        <a className="card1" style={{ width: '320px' }}>
          <h3>기술 제안</h3>
          <p className="small"style={{fontSize:'22px'}}>(예정)</p>
        </a>
      </div>
    </div>

  );
};