import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from '@mui/x-charts/BarChart';

// import apies
import { getCustomerByAgeAndSex } from 'apies/status/fetcher';

export const GenderAgeChart = () => {
  const startHealthDate = useSelector(state => state.startHealthDate);
  const endHealthDate = useSelector(state => state.endHealthDate);

  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);

  useEffect(() => {
    if (startHealthDate && endHealthDate) {
      const formData = [startHealthDate, endHealthDate];

      getCustomerByAgeAndSex(formData)
        .then(response => {
          const data = response.data;
          const maleData = data.find(item => item.sex === 'Male');
          const femaleData = data.find(item => item.sex === 'Female');

          setMaleCount(maleData ? maleData.count : 0);
          setFemaleCount(femaleData ? femaleData.count : 0);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [maleCount, femaleCount]);

  return (
    <BarChart
      xAxis={[{
        scaleType: 'band',
        data: [''],
        labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' },
        fontSize: 14,
      }]}
      series={[
        { data: [maleCount], label: 'Male', color: '#89c2d9', labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' } },
        { data: [femaleCount], label: 'FeMale',  color: '#ffb3c1', labelStyle: { fontFamily: '"Noto Sans KR", sans-serif' } },
      ]}
      width={630}
      height={300}
      barLabel="value"
    />
  );
};

export default GenderAgeChart;
