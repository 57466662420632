import TechIndex from "../../../Techindex";
import {Layout} from 'antd'; 

const OCRresultMain = () => { 
    return (
        <Layout.Content> 
            {/* 타이틀 */}
            <div className="title-area">
                <div className="main-title">
                OCR 테스트 화면
                </div>
                <p className="sub-title">
                이미지를 텍스트로 변환하는 기술인 OCR의 테스트 화면입니다.
                </p>
            </div>

            {/* 기술영역 */}
            <div className="tech-area" style={{width:'80%', display:'grid',gridTemplateColumns: '30% 70%'}}>
                <div style={{textAlign:'center'}}>
                    <h3>처방전</h3>
                    <div className="ocr-content" >
                    </div>
                </div>
                <div style={{textAlign:'center', marginLeft:'55px' }}>
                    <h3>결과물</h3>
                    <div className="ocr-content_result" >
                        

                    </div>
                </div>
                
            </div>
        </Layout.Content>
    );
};

const OCRresult = () => {
    return (
        <TechIndex>
            <OCRresultMain />
        </TechIndex>
    );
};

export default OCRresult;
