import { Layout } from 'antd';
import TechIndex from "../../../Techindex";
import React from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { TitleItem } from 'componets/Item';

const { Content } = Layout;


const CarMessage = () => {
    const iframeSrc = "https://bot.aimmed.io/chatbot/486?userInfo=aAeQOzaRuZxrK0hbhUfeSPbdccglcu3WZgm0JchDLUc%3D&code=TXWwDxI1WWwdiYRYrHpmTw%3D%3D&clcoInfo=IB4Bz0oNytW3XAAjACHnMQ%3D%3D&callbackUrl=2IWHcH9Jn%2BvaOslrYXuLK0B%2FM%2BNzVaQs89ze0EM0M4zwtMc%2BSKI%2BINIkaF8WG%2BOL";

    return (
        <Content>
            <TitleItem style={{marginBottom:'2%'}}>
                <div className="title-area">
                    <div className="main-title">
                        챗봇 대시보드 테스트 화면
                    </div>
                    <p></p>
                    <div className="sub-title">
                        시나리오 기반 챗봇을 확인할 수 있는 테스트 화면입니다.
                    </div>
                </div>
            </TitleItem>

            <TitleItem style={{height:'900px'}}>
            <div className="tech-area-v2" style={{ width: '375px', marginTop:'5%', height: '640px', marginLeft: 'auto', marginRight: 'auto', border: '10px solid rgb(55, 71, 104)', borderRadius: '36px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', position: 'relative' }}>
                <div style={{ height: '30px', width: '100%', borderTopLeftRadius: '36px', borderTopRightRadius: '36px', position: 'absolute', top: '0', left: '0', zIndex: '1' }}></div>
                <iframe src={iframeSrc} width="100%" height="100%" title="Chatbot_exam" style={{ border: 'none', borderRadius: '0 0 36px 36px' }}></iframe>
            </div>
            </TitleItem>
        </Content>
    );
};


const CareMessanger = () => {
    return (
        <TechIndex>
            <CarMessage />
        </TechIndex>
    );
};


export default CareMessanger;
