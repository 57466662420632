import axios from "axios";

const default_url = process.env.REACT_APP_API_URL;

const urls = {

    createCommentsURL: `${default_url}/api/comment/create`,
    deleteCommentURL: `${default_url}/api/comment/delete`
};

const token = localStorage.getItem('JWT-token');

export const createComment = (postId, formData) => axios({
    method: 'post',
    url: `${urls.createCommentsURL}/${postId}`,
    data: formData,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})

export const deleteComment = (commentId) => axios({
    method: 'get',
    url: `${urls.deleteCommentURL}/${commentId}`,
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
})
