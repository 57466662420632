import { React, useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TitleItem } from 'componets/Item';

// import antd
import { Spin } from 'antd';

import axios from 'axios';

// import apies
import { getWeeklyUserCount } from 'apies/status/homeFetcher';
import { getTodayTestRate } from 'apies/status/homeFetcher';
import { showTodayTestRate } from 'apies/status/homeFetcher';

export const TechWeekly = () => {

    const palette = ['#023047', '#219ebc', '#ffb5a7', '#cae9ff', '#31572c', '#2ec4b6', '#f48c06']

    const [dailyData, setDailyData] = useState([]);
    const [xAixDatas, setXAixDatas] = useState([]);
    
    const [seriesWeeklyDatas, setSeriesWeeklyDatas] = useState([]);
    const [seriesWeeklyStatus, setSeriesWeeklyStatus] = useState(false);

    const today = new Date().toISOString().slice(0, 10);

    const [dataList,setDataList] = useState([]);
    const [todayStatus, setTodayStatus]= useState(false);

    useEffect(() => {
        const fetchData = () => {
            const xAxisData = [];

            for (let i = 6; i >= 0; i--) {
                const date = new Date();
                date.setDate(date.getDate() - i);
                const formattedDate = date.toISOString().slice(0, 10);
                const lightDate = formattedDate.slice(-2);
                xAxisData.push(lightDate);
            }

            setXAixDatas(xAxisData);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const endDate = new Date(currentDate);
            endDate.setDate(currentDate.getDate() + 1);

            const key0 = `HealthStatisticsCounts-${today}`;
            const key1 = `ChatDataCounts-${today}`;
            const key2 = `HealthDataCounts-${today}`;
            const key3 = `ChatCounts-${today}`;
            const key4 = `AIChatCounts-${today}`;
            const key5 = `OCRCounts-${today}`;
            const key6 = `HeraCounts-${today}`;

            const dataKeys = {
                [key0]: '건강통계 데이터',
                [key1]: '챗봇 데이터',
                [key2]: "헬스 데이터",
                [key3]: '챗봇',
                [key4]: 'AI챗봇',
                [key5]: 'OCR',
                [key6]: 'Her-A'
            };

            const keys = [key0, key1, key2, key3, key4, key5, key6];

            keys.forEach(key => {
                const item = localStorage.getItem(key);
                
                if (item === null || item === 'NaN') {
                    localStorage.setItem(key, 0);
                }
            });

        let formData = new FormData();

        formData.append('date',today);
        formData.append('healthStatisticsCounts', localStorage.getItem(key0));
        formData.append('chatDataCounts', localStorage.getItem(key1));
        formData.append('healthDataCounts', localStorage.getItem(key2));
        formData.append('chatbotCounts', localStorage.getItem(key3));
        formData.append('aiChatbotCounts', localStorage.getItem(key4));
        formData.append('ocrCounts', localStorage.getItem(key5));
        formData.append('heraCounts', localStorage.getItem(key6));
        
        getTodayTestRate(formData)
            .then(res => {
                console.log(res.data);
            })
            
        }

        fetchData();

        // axios.post(`${process.env.REACT_APP_API_URL}/api/home/todayTest/save`, null, { params: data})
        //     .then(res => {
        //         console.log(res.data);
        //     })

        getWeeklyUserCount()
            .then(response => {
                const weeklyDatas = response.data.map(item => item.count).reverse();
                setSeriesWeeklyDatas(weeklyDatas);
                setSeriesWeeklyStatus(true);
            })
            .catch(error => {
                console.log(error)
            })

    }, []);

    useEffect(() =>{

        showTodayTestRate(today)
            .then(response => {
                let testList = response.data;
                setDataList([
                    testList['healthStatisticsCounts'],
                    testList['chatDataCounts'],
                    testList['healthDataCounts'],
                    testList['chatbotCounts'],
                    testList['aiChatbotCounts'],
                    testList['ocrCounts'],
                    testList['heraCounts']
                ]);
                setTodayStatus(true);
            })
    }, [today])

    // 오늘 사용 기술
    const xAxisLabels = ['건강통계','챗봇데이터', '헬스데이터','챗봇','AI챗봇','OCR',"Her-a" ] ;
    const seriesData = dataList;

    return (
        <div style={{ marginLeft: '20px', display: 'grid', gridTemplateRows: 'auto auto', gap: '10px' }}>
            <TitleItem>
                <div style={{ fontFamily: '"Noto Sans KR", sans-serif', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <h3 style={{ marginBottom: '25px' }}>오늘의 사용 기술</h3 >
                    {todayStatus ? <BarChart
                        series={[{ data: seriesData }]}
                        height={140}
                        width={600}
                        xAxis={[{
                            data: xAxisLabels,
                            scaleType: 'band',
                            colorMap: {
                                type: 'ordinal',
                                colors: palette,
                            },
                        }]}
                        margin={{ top: 10, bottom: 30, left: 60, right: 20 }}
                    /> : <Spin style={{marginBottom:'20%'}} size='default'/>}
                </div>
            </TitleItem>
            <TitleItem>
                <div style={{ fontFamily: '"Noto Sans KR", sans-serif', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <h3 style={{ marginBottom: '25px' }}>주간 이용자 수(9월)</h3>
                    {seriesWeeklyStatus ? <LineChart
                        xAxis={[{ scaleType: 'point', data: xAixDatas }]}
                        series={[{
                            data: seriesWeeklyDatas,
                            showMark: false,
                            color: '#023047'
                        }]}
                        height={140}
                        width={600}
                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    /> : <Spin style={{marginBottom:'20%'}} size='default'/> }
                   
                </div>
            </TitleItem>
        </div>
    );
}
