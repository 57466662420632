import React from 'react';
import { LaptopOutlined,HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import '../css/AntC.css';


const { Header, Content, Footer, Sider } = Layout;
const items1 = ['Home', 'List', 'App']; 
const items2 = [
  {
    key: 'MainMenu_1',
    icon: React.createElement(LaptopOutlined),
    label: <Link to="../admin/dashboard"><div style={{color:'black'}}>대시보드</div></Link> ,
    children: [
      {
        key: 'sub1',
        label: '챗봇데이터',
      },
      {
        key: 'sub2',
        label: '헬스데이터',
      }
    ]
  },
  {
    key:'UnderMenu_1',
    label: <div style={{ paddingLeft: '20px',color:'deepskyblue' }}>+하위메뉴추가</div>
  }, 

  {
    key: 'MainMenu_2',
    icon: React.createElement(LaptopOutlined),
    label: '챗봇', 
  },
  {
    key:'UnderMenu_2',
    label: <div style={{ paddingLeft: '20px',color:'deepskyblue' }}>+하위메뉴추가</div>
  },
  {
    key: 'MainMenu_3',
    icon: React.createElement(LaptopOutlined),
    label: 'AI챗봇', 
  },
   {
    key:'UnderMenu_3',
    label: <div style={{ paddingLeft: '20px',color:'deepskyblue' }}>+하위메뉴추가</div>
  },
  {
    key: 'MainMenu_4',
    icon: React.createElement(LaptopOutlined),
    label: 'OCR', 
  },
  {
    key:'UnderMenu_4',
    label: <div style={{ paddingLeft: '20px',color:'deepskyblue' }}>+하위메뉴추가</div>
  },
  {
    key: 'MainMenu_5',
    icon: React.createElement(LaptopOutlined),
    label: 'Her-a', 
  },
  {
    key:'UnderMenu_5',
    label: <div style={{ paddingLeft: '20px',color:'deepskyblue' }}>+하위메뉴추가</div>
  },
  {
    key:'AddMainMenu',
    label:<div style={{ paddingLeft: '10px',color:'deepskyblue', fontSize:'16px' }}>+메뉴추가</div>
  }
];
const AdminIndex = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{height:"100vh"}}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="demo-logo" />
         {/* HomeOutlined 아이콘*/}
         <Link to="/Home">
         <HomeOutlined style={{ fontSize: '35px', marginRight: '16px',transform: 'translateX(25px) translateY(5px)',color:'gray'}} />
         </Link>
         <div style={{paddingLeft:'22px', color:'red'}}>
          <Link to="../admin/home">수정 최종 완료</Link>
         </div>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={items1}
          style={{
            flex: 1,
            minWidth: 0
          }}
        />
      </Header>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
          items={items1}
        />
        <Layout
          style={{
            padding: '24px 0',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Sider
            style={{
              background: colorBgContainer,
            }}
            width={200}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{
                height: '100%',
              }}
              items={items2}
            />
            
          </Sider>
          <Content
            style={{
              padding: '0 24px',
              height: '100%'
              // minHeight: 280,
            }}
          >
          {children}
          </Content>
        </Layout>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        created by Lee
      </Footer>
    </Layout>
  );
};
export default AdminIndex;