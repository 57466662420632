import TechIndex from "../../../Techindex";
import { React, useState } from 'react';
import { Layout, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "@mui/material";
import { Grid } from "@mui/material";

// import componets
import { Checkpassword } from "./components/checkpassword";
import { InputFileAndPrompt } from "./components/inputdatas";
import { Chatting } from "./components/chatting";

import { TitleItem } from "componets/Item";

const { Content } = Layout;

const AichatbotTitle = () => {

  const infoUserName = useSelector(state => state.infoUserName);
  console.log('infoUserName', infoUserName);

  const isChatBotCreating = useSelector(state => state.isChatBotCreating);
  console.log('isChatBotCreating', isChatBotCreating);

  if (isChatBotCreating === false) {
    return (
      <Layout.Content>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={15}>
              <TitleItem
              >
                {/* 타이틀 */}
                <div className="title-area">
                  <div className="main-title">
                    AI 챗봇 테스트 화면
                  </div>
                  <p></p>
                  <div className="sub-title">
                    GPT, RAG 챗봇을 확인할 수 있는 테스트 화면입니다.
                  </div>
                </div>
              </TitleItem>
            </Grid>
            <Grid item xs={20}>
              <TitleItem>
                {/* 기술영역 */}
                <div className="tech-area" style={{ display: 'grid', margin: '0 auto' }}>
                  <div style={{ marginTop: '10px', marginBottom: '10px', paddingRight: '25px', paddingLeft:'25px', paddingBottom:'25px', backgroundColor: '#eff1f6', borderRadius: '2%' }}>
                    {/* 비밀번호 입력 */}
                    <Checkpassword />
                    <p></p>
                    <p></p>
                    {/* <InputPDF/> */}
                    <InputFileAndPrompt />

                  </div>
                </div>
              </TitleItem>
            </Grid>
          </Grid>
        </Box>

      </Layout.Content>
    );

  }

  else if (isChatBotCreating === 'create' || isChatBotCreating === true) {
    return (
      <Content>
      <TitleItem style={{ marginBottom: '2%' }}>
        <div className="title-area">
          <div className="main-title">
            AI 챗봇 테스트 화면
          </div>
          <p className="sub-title">
            GPT, RAG 등 AI챗봇을 확인할 수 있는 테스트 화면입니다.
          </p>
        </div>
      </TitleItem>
      <Chatting/>
      </Content>
    )
  }


};

const AiChatbot = () => {
  return (
    <TechIndex>
      <AichatbotTitle />
    </TechIndex>
  );
};


export default AiChatbot;
