import { createChatBotMessage } from 'react-chatbot-kit';

const MybotAvatar = () => {
  return (
    <div>
        <img  src={require("./images.png")} style={{width:'40px'}}/>
    </div>
  )
}


const config = {
  initialMessages: [createChatBotMessage(` "안녕하세요! 궁금한 내용을 입력해주세요."`)],
  customComponents: {
    // Replaces the default header
   header: () => <div style={{ marginTop:'5px',borderBottom:'2px solid rgb(226, 226, 226)',padding: "5px", borderRadius: "3px" }}>테스트챗봇</div>,
   botAvatar: () => <MybotAvatar/>,
  },
};

export default config;
