import TechIndex from "Techindex";
import { useSelector, useDispatch } from 'react-redux';

// import antd
import { Radio, Layout, Input, Spin } from 'antd';
import { Card, Space } from 'antd';
import { Button, DatePicker as AntDatePicker, Modal } from 'antd';

import { TitleItem } from "componets/Item";

const { RangePicker } = AntDatePicker;

export const Info = () => {
    return (
        <Layout.Content>
            {/* 타이틀 */}
            <TitleItem >
                <div className="title-area" >
                    <p className="main-title" style={{ fontSize: '17px' }} >
                        이미지
                    </p>
                </div>
            </TitleItem>
            <TitleItem style={{ marginTop: '1%' }}>
                <div className="tech-area">
                    <div style={{ textAlign: 'left', fontSize:'15px'  }}>
                        <span style={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>O</span>
                        <span style={{ fontWeight: '470', color: '#000000' }}>CR(Optical Character Recognition, 광학문자인식)은 컴퓨터가 글씨를 인지하여 텍스트를 데이터로 치환하는 기술입니다.</span>
                        <p>사용자가 사진을 첨부하면, 그 사진을 텍스트로 변환하고 '후처리'과정을 통해 맞춤법 체크 및 보완을 거쳐 인식한 문장을 도출합니다.</p>
                        <p></p>
                            <img src="/files/img/ocr1.png" alt="ocr1" style={{ width: '70%' }} />
                        <p></p>
                        <Card title="처방전 OCR 테스트 하기" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%', color: '#000000', fontSize:'15px'  }} >
                            <p></p>
                            이미지 → OCR에서 OCR을 테스트할 수 있습니다.
                            <br/>샘플을 선택하고 테스트를 시작하면 OCR에서 인식한 텍스트가 표로 노출됩니다.
                            <p></p>
                            <img src="/files/img/ocr2.png" alt="ocr2" style={{ width: '70%' }} />
                            <p></p>
                            실제 처방전을 테스트하고 싶으면 [첨부하기]를 통해 테스트해보세요.
                            <br/>현재는 후처리 과정이 없기 때문에 OCR이 인식한 그대로 텍스트가 노출됩니다.
                        </Card>
                    </div>
                </div>

            </TitleItem>
        </Layout.Content>
    )
}

const OCRInfo = () => {
    return (
        <TechIndex>
            <Info />
        </TechIndex>
    );
};

export default OCRInfo;
