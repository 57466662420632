import { React, useState, useEffect } from 'react'
import { Radio, Alert, Spin, Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Table } from 'antd';

// import material UI
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// import redux
import { setInsertImage } from '../../../../../action/action';
import { setIsImageCreating } from '../../../../../action/action';

// import apies
import { postImages } from '../../../../../apies/status/fetcher';
import { postGetImages } from '../../../../../apies/status/fetcher';
import { postGetImageToText } from '../../../../../apies/status/fetcher';

// import TitleItem
import { TitleItem } from 'componets/Item';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ModalSuccess = () => {
    Modal.success({
        content: '업로드 완료되었습니다.',
    });
};

const ModalStyle = {
    content: {
        fontFamily: '"Noto Sans KR", sans-serif'
    }
}

export const InputPDF = () => {

    const [value, setValue] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    const userImage = useSelector(state => state.userImage);
    const ImageCreating = useSelector(state => state.ImageCreating);

    const dispatch = useDispatch();

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onCreateThumbnailUrl = (e) => {
        const file = e.target.files[0];

        setSelectedFile(file);
        console.log('업로드 파일', file);

        //redux에 파일 저장
        dispatch(setInsertImage(file));

        // 파일을 읽어서 썸네일 생성
        const reader = new FileReader();
        reader.onload = (event) => {
            setThumbnailUrl(event.target.result);
        };
        reader.readAsDataURL(file);
    };

    const handleImageCreation = async (ImageForm) => {
        try {
            const response = await postImages(ImageForm);
            console.log('S3 path', response.data);

            let imagesResponseData = new FormData();
            imagesResponseData.append('img_path', response.data);

            dispatch(setIsImageCreating('create'));

            // 응답 형태를 이미지로 받아오는 API
            // const base64Image = await postGetImages(imagesResponseData);

            // if (base64Image) {
            //     dispatch(setIsImageCreating('success'));
            // }

            // 응답 형태를 텍스트로 받아오는 API
            postGetImageToText(imagesResponseData)
                .then(response => {
                    const dataToStore = {
                        name: response.data.name,
                        residentRegistrationNumber: response.data.resident_registration_number,
                        hospital: response.data.hospital,
                        phoneNumber: response.data.phone_number,
                        faxNumber: response.data.fax_number,
                        drugs: response.data.drugs
                    };
                    localStorage.setItem('ocrImgText', JSON.stringify(dataToStore));

                    dispatch(setIsImageCreating('success'));
                })
                .catch(error => {
                    console.error('문자 변환 실패', error)
                    dispatch(setIsImageCreating('false'));
                })

        } catch (error) {
            console.error('Error during image creation', error);
            dispatch(setIsImageCreating('false'));
        }
    }

    const OnUploadFile = async () => {

        // OCR 테스트 이용률 집계
        let ocrCount = localStorage.getItem('OCRCount');

        if (ocrCount === null || ocrCount === "null") {
            ocrCount = 0;
        } else {
            ocrCount = parseInt(ocrCount);
        }
    
        ocrCount += 1;
        localStorage.setItem('OCRCount', JSON.stringify(ocrCount));

        // OCR 일일 이용횟수 집계
        const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD

        const dailyStoredData = localStorage.getItem(`OCRCounts-${today}`);
        const dailyDataCount = parseInt(dailyStoredData, 10) || 0;

        const dailyCounts = dailyDataCount + 1;

        // const dailyChatCount = {
        //     count: dailyCounts,
        //     timeStamp: new Date().toISOString()
        // };

        localStorage.setItem(`OCRCounts-${today}`, dailyCounts);

        if (userImage) {
            let ImageForm = new FormData();
            ImageForm.append('userImage', userImage);

            //서버에 이미지 전달
            handleImageCreation(ImageForm);
        }

        else if (value === 1 || value === 2 || value === 3) {
            let imagesResponseData = new FormData();

            if (value === 1) {
                imagesResponseData.append('img_path', 'rdl-image/ocr/처방전_test.jpg');
            }
            else if (value === 2) {
                imagesResponseData.append('img_path', 'rdl-image/ocr/처방전테스트2.jpg');
            }
            else if (value === 3) {
                imagesResponseData.append('img_path', 'rdl-image/ocr/처방전테스트3.jpg');
            }

            dispatch(setIsImageCreating('create'));

            // 응답 형태를 텍스트로 받아오는 API
            postGetImageToText(imagesResponseData)
                .then(response => {
                    const dataToStore = {
                        name: response.data.name,
                        residentRegistrationNumber: response.data.resident_registration_number,
                        hospital: response.data.hospital,
                        phoneNumber: response.data.phone_number,
                        faxNumber: response.data.fax_number,
                        drugs: response.data.drugs
                    };
                    localStorage.setItem('ocrImgText', JSON.stringify(dataToStore));

                    dispatch(setIsImageCreating('success'));
                })
                .catch(error => {
                    console.error('문자 변환 실패', error)
                    dispatch(setIsImageCreating('false'));
                })

            // 샘플선택 다시 구현 중 

            // dispatch(setIsImageCreating('create'));

            // const base64Image = await postGetImages(imagesResponseData);

            // if (base64Image) {
            //     dispatch(setIsImageCreating('success'));
            // }
        }
        else {
            console.log('..')
        }

    }

    if (ImageCreating === false) {
        return (
            <div>
                <TitleItem style={{ marginBottom: '2%' }}>
                    <h3>처방전 첨부</h3>
                    ※이미지 내 텍스트의 위치와 정보를 정확하게 인지하기 위해서는 이미지 파일 크기가 3024X3024 사이즈여야 OCR 인식 정확도가 올라갑니다.
                </TitleItem>

                <TitleItem>
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>
                            <h3>샘플A</h3>
                        </Radio>
                    </Radio.Group>
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={2}>
                            <h3>샘플B</h3>
                        </Radio>
                    </Radio.Group>
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={3}>
                            <h3>샘플C</h3>
                        </Radio>
                    </Radio.Group>
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={4}>
                            <h3>첨부하기</h3>
                        </Radio>
                    </Radio.Group>

                    <div style={{ position: 'relative', height:'800px' }}>
                        <span style={{ fontFamily: '"Noto Sans KR", sans-serif', color: 'black' }}>처방전이 없을 경우, 처방전 샘플로 내용을 확인할 수 있습니다.</span>
                        <p></p>
                        {value === 1 && (
                            <div style={{ textAlign: 'center', width: '80%', marginLeft: '12%'}}>
                                <img src={require("./prescriptiontest.jpg")} alt="처방전샘플" style={{ width: '45%', height:'50%' }} />
                            </div>
                        )}
                        {value === 2 && (
                            <div style={{ textAlign: 'center', width: '75%', marginLeft: '12%' }}>
                                <img src={require("./prescriptiontest2.jpg")} alt="처방전샘플" style={{ width: '45%' }} />
                            </div>
                        )}
                        {value === 3 && (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <img src={require("./prescriptiontest3.jpg")} alt="처방전샘플" style={{ width: '45%' }} />
                            </div>
                        )}
                        {value === 4 && (
                            <div style={{ textAlign: 'center'}}>
                                <p></p>
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    style={{ marginBottom: '10px' }}
                                    className="upload-button"
                                >
                                    이미지 업로드
                                    <VisuallyHiddenInput onChange={onCreateThumbnailUrl} type="file" />
                                </Button>
                                {thumbnailUrl && (
                                    <div>
                                        {/* <ModalSuccess style={ModalStyle}/> */}
                                        <p></p>
                                        <img src={thumbnailUrl} alt="Uploaded Thumbnail" />
                                    </div>
                                )}
                            </div>
                        )}
                        <p></p>
                        <div style={{ position: 'absolute', bottom: '20px', right: '20px' }}>
                            <Button variant="outlined" onSubmit={ModalSuccess} onClick={OnUploadFile} style={{ fontFamily: '"Noto Sans KR", sans-serif' }}>테스트 시작하기</Button>
                        </div>
                        <p></p>
                    </div>
                </TitleItem>
            </div>
        );
    }
    if (ImageCreating === 'create') {
        return (
            <div style={{ width: '100%', height: '650px', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                <h3>이미지 생성 중입니다.</h3>
                <p>시간이 다소 소요될 수 있습니다.</p>
                <Spin size="large" />
            </div>
        );
    } else if (ImageCreating === 'false') {
        return ('');
    } else if (ImageCreating === 'success') {
        const ocrImgText = localStorage.getItem('ocrImgText');

        const dataObject = JSON.parse(ocrImgText);
        const nameValue = dataObject.name;
        const residentRegistrationNumberValue = dataObject.residentRegistrationNumber;
        const hospitalValue = dataObject.hospital;
        const phoneNumberValue = dataObject.phoneNumber;
        const faxNumberValue = dataObject.faxNumber;
        const drugsValue = dataObject.drugs;

        const columns = [
            {
                title: 'Entity',
                dataIndex: 'entity',
            },
            {
                title: 'Result',
                dataIndex: 'result',
            },
        ];
        const data = [
            {
                key: '1',
                entity: '환자성명',
                result: nameValue,
            },
            {
                key: '2',
                entity: '주민등록번호',
                result: residentRegistrationNumberValue,
            },
            {
                key: '3',
                entity: '의료기관명',
                result: hospitalValue,
            },
            {
                key: '4',
                entity: '전화번호',
                result: phoneNumberValue,
            },
            {
                key: '5',
                entity: '팩스번호',
                result: faxNumberValue,
            },
            {
                key: '6',
                entity: '의약품명',
                result: drugsValue,
            },
        ];

        return (
            <div className="tech-area" style={{ width: '70%', display: 'grid', gridTemplateColumns: '50% 50%', padding: '20px', backgroundColor: '#eff1f6' }}>
                {userImage ? (
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={URL.createObjectURL(userImage)} alt="Uploaded Image" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                ) :
                    (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {value === 1 && (
                                <img src={require("./prescriptiontest.jpg")} alt="처방전샘플" style={{ width: '70%' }} />
                            )}
                            {value === 2 && (
                                <img src={require("./prescriptiontest2.jpg")} alt="처방전샘플" style={{ width: '50%' }} />
                            )}
                            {value === 3 && (
                                <img src={require("./prescriptiontest3.jpg")} alt="처방전샘플" style={{ width: '80%' }} />
                            )}
                        </div>
                    )}
                <div>
                    <Divider style={{ fontFamily: '"Noto Sans KR", sans-serif' }}>처리 결과</Divider>
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Table columns={columns} dataSource={data} size="small" pagination={false} style={{ height: '80%', fontSize: '18px' }} />
                    </div>
                </div>
            </div>
        );
    }
};