import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';

// import apies
import { getContentsCount } from '../../../../../../apies/status/fetcher';

export const UserKeyword = () => {

  const startDate = useSelector(state => state.startDate);
  const endDate = useSelector(state => state.endDate);

  const containerRef = useRef(null);

  const [contents, setContents] = useState([]);
  const [contentsCount, setContentsCount] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      let formData = [startDate, endDate];
      getContentsCount(formData)
        .then(response => {
          setContents(Object.keys(response.data));
          setContentsCount(Object.values(response.data));
        })
        .catch(error => {
          console.error('Error fetching contents count:', error);
        });
    }
  }, [startDate, endDate]);

  const keywords = contents.map((content, index) => ({
    text: content,
    value: contentsCount[index],
  }));

  const getContainerSize = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      return { width: rect.width, height: rect.height };
    }
    return { width: 0, height: 0 };
  };

  return (
    <div ref={containerRef} style={{ height: '600px', overflow: 'hidden' }}>
      {startDate && endDate ? (
        <ReactWordcloud 
          options={{
            fontFamily: '"Noto Sans KR", sans-serif',
            fontSizes: [20, 15],
            fontWeight: 'bold',
            display: 'None'
            
          }}
          words={keywords}
          size={[getContainerSize().width, getContainerSize().height]} />
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

export default UserKeyword;
