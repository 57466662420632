import TechIndex from '../../../Techindex';
import { React, useState } from 'react';

// import material UI
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { TitleItem } from 'componets/Item';

// import antd--
import { Layout } from 'antd';
import { Radio, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Space } from 'antd';

// import react-draft-wysiwyg  - 텍스트 에디터
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Form } from 'react-router-dom';

// import apies
import { createTrendPost } from 'apies/status/postFetcher';
import { saveFileTrendPost } from 'apies/status/postFetcher';

const { Content } = Layout;
const { confirm } = Modal;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const MyBlock = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 1rem; /* 각각의 요소들 사이의 간격 설정 */
    
    .editor {
        height: 330px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }

    .rdw-option-wrapper {
        display: flex; 
        gap: 0.5rem; 
    }
`;

export const TechTrendMain = () => {

    // 업로드 파일명 미리보기
    const [fileName, setFileName] = useState('');

    // 업로드 파일 저장
    const [uploadFile, setUploadFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadFile(file)
            setFileName(file.name);
            console.log(fileName);
        }
    };

    // 타이틀 
    const [titleValue, setTitleValue] = useState('');

    const handleInputChange = (e) => {
        setTitleValue(e.target.value);
        console.log(titleValue);
    };

    // 저장 된 사용자 닉네임 
    let userName = localStorage.getItem('userName');

    // useState로 상태관리하기 초기값은 EditorState.createEmpty()
    // EditorState의 비어있는 ContentState 기본 구성으로 새 개체를 반환 => 이렇게 안하면 상태 값을 나중에 변경할 수 없음.
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        // editorState에 값 설정
        setEditorState(editorState);
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        console.log(plainText);

        setInputExisted(plainText)
    };

    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    // 에디터에 입력된 값이 있는지 확인 
    const [inputExist, setInputExisted] = useState(false);

    const onClickEvent = () => {
        console.log('INPUT', inputExist);
        if (inputExist) {
            confirm({
                title: '작성중인 내용이 있습니다.',
                icon: <ExclamationCircleFilled />,
                content: '정말 다른 메뉴로 이동하시겠습니까?',
                okText: '계속 작성하기',
                okType: 'primary',
                cancelText: '목록으로 돌아가기',
                onOk() {
                    console.log('OK');
                },
                onCancel() {
                    window.location.href = '/techBoard'
                },
            });
        }
        else {
            window.location.href = '/techBoard';
        }
    }

    // 게시글 등록 이벤트
    const onSummitEvnet = () => {

        let formData = new FormData();
        let fileFormData = new FormData();
        const today = new Date();
        formData.append('title', titleValue)
        formData.append('date', today.toISOString().split('T')[0])
        formData.append('status', value)
        formData.append('content', inputExist)
        formData.append('filePath', fileName)
        formData.append('user',userName);

        fileFormData.append('file',uploadFile)

        if (inputExist && titleValue) {
            createTrendPost(formData)
                .then(response => {
                    saveFileTrendPost(fileFormData)
                        .then(response => {
                            console.log(response.data);
                        });
                    Modal.success({
                        content: '게시판에 글이 등록되었습니다.',

                        onOk() {
                          console.log('OK');
                          window.location.href='/techboard'
                        },

                      })
                })
        }
        else {
            Modal.warning({
                title: '경고',
                content: '제목과 내용을 입력해주세요.',
            });
        }
    }

    return (
        <Content>
            <TitleItem>
                <div className="title-area">
                    <div className="main-title">
                        기술 트렌드
                    </div>
                    <p></p>
                    <div className="sub-title">
                        기술 동향 게시판 입니다.
                    </div>
                </div>
            </TitleItem>
            <div className="tech-area" style={{ display: 'grid', fontSize: '12px', width: '100%' }}>
                <div>
                    <TitleItem style={{ marginTop: '15px' }}>
                        <div className='search-area' style={{ display: 'grid', width: '100%', gridTemplateColumns: '20% 80%' }}>
                            <div className='search-menu'>제목</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <div style={{ overflow: 'auto', backgroundColor: 'white' }}>
                                    <Input
                                        placeholder="제목을 입력하세요."
                                        value={titleValue}
                                        onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className='search-menu'>작성자</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                {userName}
                            </div>
                            <div className='search-menu'>공개여부</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={1}>공개</Radio>
                                    <Radio value={2}>비공개</Radio>
                                </Radio.Group>
                            </div>
                            <div className='search-menu' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >내용</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <MyBlock>
                                    <Editor
                                        // 에디터와 툴바 모두에 적용되는 클래스
                                        wrapperClassName="wrapper-class"
                                        // 에디터 주변에 적용된 클래스
                                        editorClassName="editor"
                                        // 툴바 주위에 적용된 클래스
                                        toolbarClassName="toolbar-class"
                                        // 툴바 설정
                                        toolbar={{
                                            // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: false },
                                        }}
                                        placeholder="내용을 작성해주세요."
                                        // 한국어 설정
                                        localization={{
                                            locale: 'ko',
                                        }}
                                        // 초기값 설정
                                        editorState={editorState}
                                        // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                                        onEditorStateChange={onEditorStateChange}

                                    />
                                </MyBlock>
                            </div>
                            <div className='search-menu'>파일첨부</div>
                            <div className='menu-item' style={{ textAlign: 'left' }}>
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    style={{ marginBottom: '10px', marginRight: '15px', height: '25px', fontFamily:'"Noto Sans KR", sans-serif' }}
                                    className="upload-button"
                                >
                                    첨부하기
                                    <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                </Button>
                                <span style={{ textDecoration: 'underline' }}>{fileName}</span>
                            </div>
                        </div>
                        <div style={{ textAlign: 'right', margin: 'auto', marginTop: '1%' }}>
                            <Button onClick={onClickEvent} variant="outlined" style={{ fontFamily: '"Noto Sans KR", sans-serif', marginRight: '2%' }}>목록으로 이동</Button>
                            <span ><Button variant="contained" onClick={onSummitEvnet} style={{ fontFamily: '"Noto Sans KR", sans-serif' }}>등록</Button></span>
                        </div>
                    </TitleItem>
                </div>
            </div>
        </Content>
    )
}

const TechTrendCreate = () => {
    return (
        <TechIndex>
            <TechTrendMain />
        </TechIndex>
    );
};

export default TechTrendCreate;