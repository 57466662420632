import { React, useState, useEffect } from 'react'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useSelector } from 'react-redux';

// import apies
import { getCompanyCount } from '../../../../../../apies/status/fetcher';

export const UserCompanyCount = () => {

    const palette = ['#89c2d9','#468faf','#01497c']

    const startDate = useSelector(state => state.startDate);
    const endDate = useSelector(state => state.endDate);

    const [company, setCompany] = useState([]);
    const [companyCount, setCompanyCount] = useState([]);

    useEffect(() => {
        if (startDate && endDate) {
            let formData = [startDate, endDate];
            getCompanyCount(formData)
                .then(response => {
                    setCompany(Object.keys(response.data));
                    setCompanyCount(Object.values(response.data));
                })
                .catch(error => {
                    console.error('Error', error);
                });
        }
    }, [startDate, endDate]);

    const data = company.map((comp, index) => ({
        id: index,
        value: companyCount[index],
        label: comp
    }));

    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    return (
        <div style={{ height: '350px', justifyContent: 'center', alignContent: 'center', textAlign: 'center', borderRadius: '4%' }}>
            <PieChart
                colors={palette}
                slotProps={{
                    legend: {
                        labelStyle: {
                            fontFamily: '"Noto Sans KR", sans-serif'
                        },
                        position: {
                            vertical: 'middle',
                            horizontal: 'right',
                        },
                        itemMarkWidth: 20,
                        itemMarkHeight: 2,
                        markGap: 5,
                        itemGap: 10,
                    }
                }}
                series={[
                    {
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray', paddingAngle: '10px' },
                        arcLabel: getArcLabel,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 14,
                        fontFamily: '"Noto Sans KR", sans-serif',
                    },
                }}
                height={270}
            />
            <p></p>
        </div>
    )
}
