import React from 'react';
import { Layout } from 'antd';
import {  Input, Typography } from 'antd';

import AdminIndex from '../AdminIndex';
import '../../css/HomeC.css';
import '../../css/AntC.css';
// import axios from 'axios';

const { Content } = Layout;


const AdminDashboardMain = () => {

    return (
        <Content>
        {/* 타이틀 */}
        <div className="title-area">
            <h5 className="main-title">
            대시보드 수정 페이지
            </h5>
            <p className="sub-title">
            대시보드 수정 페이지입니다.
            </p>
        </div>

        {/* 기술영역 */}
        <div className="tech-area" style={{width:'700px'}}>
        <div>
            <Typography.Title level={5}>메뉴명*</Typography.Title>
            <Input
            count={{
                show: true,
            }}
            defaultValue="대시보드🔥🔥🔥"
            />
        </div>
        <div>
            <Typography.Title level={5}>info 툴팁</Typography.Title>
            <Input 
            count={{
                show: true,
            }}
            defaultValue="대시보드 영역입니다~"
            />
        </div>
        </div>

        </Content>
    );
};


const AdminDashboard = () =>{
    return(
        <AdminIndex>
        <AdminDashboardMain/>
        </AdminIndex>
            
    );
};

export default AdminDashboard;