import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import '../css/LoginC.css';

import { Modal, Space } from 'antd';
import { setUserName } from '../action/action';

function PasswordChange() {

    const dispatch = useDispatch();

    const [userName, setUserName] = useState('');
    useEffect(() => {
        const storedUserName = localStorage.getItem('changePWID');
        setUserName(storedUserName);
    }, [userName]); 

    console.log('TEST',userName)

    const [inputPw, setInputPw] = useState('')
    const [inputPwCheck, setInputPwCheck] = useState('')

    const [error, setError] = useState(null);

    const handleInputPw = (e) => {
        setInputPw(e.target.value)
    }

    const handleInputPwCheck = (e) => {
        setInputPwCheck(e.target.value)
    }

    const onChangePW = () => {
        if (inputPw != inputPwCheck) {
            setError("비밀번호가 일치하지 않습니다.");
        }
        else {
            const data = {
                email: userName,
                password: inputPw,
            }
            axios.post(`${process.env.REACT_APP_API_URL}/api/changePassWord`, null, { params: data })
                .then(res => {
                    Modal.success({
                        content: '비밀번호 변경이 완료되었습니다. 로그인하세요.',
                        onOk() {
                            console.log('OK');
                            window.location.href = '/login'
                        },

                    })
                });
        }
    }

    return (
        <div className="main" >
            <p className="sign" style={{ textAlign: "center" }}>비밀번호 변경</p>
            <div
                className="un"
                style={{ textAlign: "center" }}
            >{userName}</div>
            <input
                className="pass"
                type="password"
                name="password"
                style={{ textAlign: "center" }}
                placeholder="변경할 비밀번호를 입력해주세요."
                value={inputPw}
                onChange={handleInputPw}
            />
            <input
                className="pass"
                type="password"
                name="password"
                style={{ textAlign: "center" }}
                placeholder="비밀번호를 한번 더 입력해주세요."
                value={inputPwCheck}
                onChange={handleInputPwCheck}
            />
            <div style={{ margin: 'auto' }}><button type="button" className="submit" onClick={onChangePW}>변경</button></div>
            {error && <p className="error" style={{ color: "red", textAlign: "center" }}>{error}</p>} {/* 에러 메시지 출력 */}
            {/* <p className="forgot" style={{ textAlign: "center" }}><a href="/">Forgot Password?</a></p> */}
        </div>
    );
}

export default PasswordChange