import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import '../css/LoginC.css';

// import icons
import { ExclamationCircleFilled } from '@ant-design/icons';

// import antd
import { Modal, Space, Layout } from 'antd';
const { confirm } = Modal;

import { setUserName } from '../action/action';

// import apies
import { getLoginUserCount } from 'apies/status/fetcher';

function Login() {

    const dispatch = useDispatch();

    const [inputId, setInputId] = useState('')
    const [inputPw, setInputPw] = useState('')
    const [error, setError] = useState(null);

    const handleInputId = (e) => {
        setInputId(e.target.value)
    }

    const handleInputPw = (e) => {
        setInputPw(e.target.value)
    }

    // login 버튼 클릭 이벤트
    const onClickLogin = () => {
        const data = {
            email: inputId,
            password: inputPw
        };

        if (inputPw === 'aimmed3015') {
            confirm({
                title: '비밀번호 변경',
                icon: <ExclamationCircleFilled />,
                content: '비밀번호 변경을 하시겠습니까?',
                okText: '취소',
                okType: 'primary',
                cancelText: '변경',
                onOk() {
                    console.log('OK');
                },
                onCancel() {
                    localStorage.setItem('changePWID', inputId);
                    window.location.href = '/passwordChange'
                },
            });
        }

        else {
            axios.post(`${process.env.REACT_APP_API_URL}/api/login`, null, { params: data })
                .then(res => {
                    let userName = res.config.params['email']
                    // userName을 localStorage에 저장
                    localStorage.setItem('userName', userName);

                    // JWT 토큰을 localStorage에 저장
                    let token = res.headers.get("Authorization");
                    token = token.replace("Bearer ", "");
                    localStorage.setItem('JWT-token', token);

                    // userCount 증가
                    const fetchData = async () => {
                        let mounted = true;
                        const today = new Date().toISOString().slice(0, 10);
                        try {
                            const response = await getLoginUserCount();
                            console.log("TEST", response.data)

                            const data = {
                                date: today,
                                count: response.data
                            };
                            if (mounted) {
                                axios.post(`${process.env.REACT_APP_API_URL}/api/home/weeklyUser/save`, data)
                                    .then(res => {
                                        console.log("login",res.data)
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                            }

                        } catch (error) {
                            console.log('요청 실패', error);
                        }
                    };

                    fetchData()
                    .then(res => {
                        window.location.replace("/home");    
                    })
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError("이메일과 비밀번호를 확인해주세요.");
                });
        }
    }

    // const infoUserName = useSelector(state => state.infoUserName);
    // console.log('info',infoUserName);          

    return (
        <div className="main">
            <p className="sign" style={{ textAlign: "center" }}>Login</p>
            <input
                className="pass"
                type="text"
                name="email"
                style={{ textAlign: "center" }}
                placeholder="email"
                value={inputId}
                onChange={handleInputId}
            />
            <input
                className="pass"
                type="password"
                name="password"
                style={{ textAlign: "center" }}
                placeholder="Password"
                value={inputPw}
                onChange={handleInputPw}
            />
            <button type="submit" className="submit" onClick={onClickLogin} style={{ transform: "transformX:70px" }}>Login</button>
            {error && <p className="error" style={{ color: "red", textAlign: "center" }}>{error}</p>} {/* 에러 메시지 출력 */}
            {/* <p className="forgot" style={{ textAlign: "center" }}><a href="/">Forgot Password?</a></p> */}
        </div>
    );
}


export default Login;