import React, { useEffect, useState } from 'react';

import { PieChart } from '@mui/x-charts/PieChart';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { getTestRate } from 'apies/status/homeFetcher';

import { Spin } from 'antd';

export const TechUtilizationRate = () => {

    const palette = ['#023047','#219ebc','#ffb5a7','#cae9ff','#31572c','#2ec4b6','#f48c06']

    const [showHealthStatisticsCount, setHealthStatisticsCount] = useState(0);
    const [showChatDataCount, setChatDataCount] = useState(0);
    const [showHealthDataCount, setHealthDataCount] = useState(0);
    const [showChatCount, setChatCount] = useState(0);
    const [showAiChatCount, setAiChatCount] = useState(0);
    const [showOCRCount, setOCRCount] = useState(0);
    const [showHeraCount, setHeraCount] = useState(0);

    const [testScoreStatus, setTestScoreStatus] = useState(false);

    useEffect(() => {
        const healthStatisticsCount = parseInt(localStorage.getItem('HealthStatisticsCount'), 10) || 0;
        const chatDataCount = parseInt(localStorage.getItem('ChatDataCount'), 10) || 0;
        const healthDataCount = parseInt(localStorage.getItem('HealthDataCount'), 10) || 0;
        const chatCount = parseInt(localStorage.getItem('ChatCount'), 10) || 0;
        const aiChatCount = parseInt(localStorage.getItem('AIChatCount'), 10) || 0;
        const ocrCount = parseInt(localStorage.getItem('OCRCount'), 10) || 0;
        const heraCount = parseInt(localStorage.getItem('HeraCount'), 10) || 0;

        const data = {
            allHealthStatisticsCounts: healthStatisticsCount,
            allChatDataCounts: chatDataCount,
            allHealthDataCounts: healthDataCount,
            allChatbotCounts: chatCount,
            allAiChatbotCounts: aiChatCount,
            allOCRCounts: ocrCount,
            allHeraCounts: heraCount
        };

        axios.post(`${process.env.REACT_APP_API_URL}/api/home/allTest/save`, data)
            .then(() => {
                localStorage.setItem('HealthStatisticsCount',0)
                localStorage.setItem('ChatDataCount',0)
                localStorage.setItem('HealthDataCount',0)
                localStorage.setItem('ChatCount',0)
                localStorage.setItem('AIChatCount',0)
                localStorage.setItem('OCRCount',0)
                localStorage.setItem('HeraCount',0)
                return getTestRate();
            })
            .then(res => {
                setHealthStatisticsCount(res.data['allHealthStatisticsCounts']);
                setChatDataCount(res.data['allChatDataCounts']);
                setHealthDataCount(res.data['allHealthDataCounts']);
                setChatCount(res.data['allChatbotCounts']);
                setAiChatCount(res.data['allAiChatbotCounts']);
                setOCRCount(res.data['allOCRCounts']);
                setHeraCount(res.data['allHeraCounts']);

                setTestScoreStatus(true);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const total = showHealthStatisticsCount + showChatDataCount + showHealthDataCount +
    showChatCount + showAiChatCount + showOCRCount + showHeraCount;

    const datas = [
        { id: 0, value: showHealthStatisticsCount, label: '건강통계 데이터' },
        { id: 1, value: showChatDataCount, label: '챗봇 데이터' },
        { id: 2, value: showHealthDataCount, label: '헬스 데이터' },
        { id: 3, value: showChatCount, label: '챗봇' },
        { id: 4, value: showAiChatCount, label: 'AI챗봇' },
        { id: 5, value: showOCRCount, label: 'OCR' },
        { id: 6, value: showHeraCount, label: 'Her-A' }
    ].map(item => ({
        ...item,
        label: `${item.label}: ${total ? ((item.value / total) * 100).toFixed(1) : 0}%`
    }));

    return (
        <div style={{ height: '500px',  justifyContent: 'center', alignItems: 'center', textAlign: 'center', borderRadius: '4%' }}>
            <Typography variant="h3" style={{ marginTop:'10px',fontWeight:'bold',marginBottom: '35px', fontFamily: '"Noto Sans KR", sans-serif', fontSize:'20px' }}>테스트 총이용률</Typography>
            {testScoreStatus ? <PieChart
            colors={palette}
                series={[
                    {
                        data: datas,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray', paddingAngle: 10 },
                    },
                ]}
                height={320}
            /> : <Spin style={{display:'flex', justifyContent:'center', alignItems:'center',minHeight: '30vh'}} size='large'/> }
            
        </div>
    );
};
