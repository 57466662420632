import TechIndex from "Techindex";
import { useSelector, useDispatch } from 'react-redux';

// import antd
import { Radio, Layout, Input, Spin } from 'antd';
import { Card, Space } from 'antd';
import { Button, DatePicker as AntDatePicker, Modal } from 'antd';

import { TitleItem } from "componets/Item";

export const Info = () => {
    return (
        <Layout.Content>
            {/* 타이틀 */}
            <TitleItem >
                <div className="title-area" >
                    <p className="main-title" style={{ fontSize: '17px' }} >
                        NLP
                    </p>
                </div>
            </TitleItem>
            <TitleItem style={{ marginTop: '1%' }}>
                <div className="tech-area">
                    <div style={{ textAlign: 'left', fontSize:'15px' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>H</span>
                        <span style={{ fontWeight: '470', color: '#000000' }}>er-A(Health examination results – AI automation, 헤라)는 건강검진 소견 분류 AI서비스 입니다. </span>
                        <br />건강검진 검사에 대한 종합소견 결과를 딥러닝 모델을 사용하여 자동 분류합니다.
                        <p></p>
                        주요 14개 부위 및 29개 검사에 대한 소견 결과를 '정상, 유소견, 중증유소견'으로 분류하여 기존에 수작업으로 진행하던 분류 과정을 축소하였습니다.
                        <br />
                        현재 기준으로 분류 정확도는 약 86%입니다.
                        <p></p>
                        <img src="/files/nlp/NLP1.png" alt="nlp1" style={{ width: '35%' }} />
                        <p></p>
                        <Card title="14개 주요부위 29개 검사 대상" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%', color: '#000000' , fontSize:'15px' }} >
                            <p></p>
                            갑상선 초음파, 유방, 하복부 초음파(남), 하복부 초음파(여), 상복부 초음파, 동맥경화, 흉부, 심전도, 자궁경부, 위, 척추, 관상동맥, 대장, 뇌
                        </Card>
                        <Card title="Her-A 테스트하기" size="default" style={{ paddingLeft: '1%', paddingBottom: '1%', color: '#000000' , fontSize:'15px' }} >
                            <p></p>
                            <img src="/files/nlp/NLP2.png" alt="nlp2" style={{ width: '85%' }} />
                            <p></p>
                            개별 소견 및 종합소견을 입력하여 테스트할 수 있습니다.
                            <br />
                            샘플 데이터를 통해 Her-A 결과를 확인할 수 있습니다.
                            <br />
                            *실제 모델을 구동하기 때문에 테스트 결과 도출에 시간이 소요될 수 있습니다.
                        </Card>
                    </div>
                </div>

            </TitleItem>
        </Layout.Content>
    )
}

const HeraInfo = () => {
    return (
        <TechIndex>
            <Info />
        </TechIndex>
    );
};

export default HeraInfo;
