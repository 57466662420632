import TechIndex from '../Techindex';
import { React, useEffect, useState } from 'react';

import '../css/HomeC.css';
import '../css/AntC.css';
import '../css/AdminAntC.css';

import { AudioOutlined } from '@ant-design/icons';
import { DatePicker, Space, Radio, Input, Button } from 'antd';
import { Layout, Table } from 'antd';

import { TitleItem } from 'componets/Item';

// import apies
import { getTrendPostTables } from 'apies/status/postFetcher';

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Content } = Layout;

const TechTrendMain = () => {
  const [value, setValue] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  useEffect(() => {
    getTrendPostTables()
      .then(response => {

        const newData = response.data.map(item => ({
          key: item.id.toString(),
          index: item.id.toString(),
          title: item.title,
          status: item.status === 1 ? '공개' : '비공개',
          date: item.date,
          user: item.user || '사용자 미정'

        }));
        setDataSource(newData);
        setFilteredDataSource(newData); 

      })
      .catch(error => {
        console.error('Error', error);
      });
  }, []);

  const handleSearch = (value) => {
    const inputValue = value.toLowerCase();
    const filteredData = dataSource.filter(item =>
      item.title.toLowerCase().includes(inputValue) ||
      item.user.toLowerCase().includes(inputValue)
    );
    setFilteredDataSource(filteredData);
  };

  const handleClearSearch = () => {
    setFilteredDataSource(dataSource); 
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const columns = [
    {
      title: 'NO',
      dataIndex: 'index',
      key: '1',
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: '제목',
      dataIndex: 'title',
      render: (text, record) => <a href={`/techBoard/show/${record.key}`}>{text}</a>,
      key: '2',
    },
    {
      title: '공개여부',
      dataIndex: 'status',
      key: '3',
    },
    {
      title: '작성일',
      dataIndex: 'date',
      key: '4',
    },
    {
      title: '작성자',
      dataIndex: 'user',
      key: '5',
    }
  ];

  return (
    <Content>
      {/* 타이틀 */}
      <TitleItem>
        <div className="title-area">
          <div className="main-title">
            기술 트렌드
          </div>
          <p></p>
          <div className="sub-title">
            기술 동향 게시판 입니다.
          </div>
        </div>
      </TitleItem>

      {/* 기술영역 */}
      <div className="tech-area" style={{ fontSize: '12px', width: '100%', gridTemplateRows: '50% 50%', justifySelf: 'center', alignSelf: 'center', verticalAlign:'center' }}>
        <div>
          <TitleItem style={{ marginTop: '15px' }}>
            <div className='search-area' style={{ display: 'grid', width: '100%', gridTemplateColumns: '20% 80%' }}>
              <div className='search-menu'>조회기간</div>
              <div className='menu-item' style={{ textAlign: 'left', padding:'15px' }}>
                <Space direction="vertical" size={5}>
                  <RangePicker
                    style={{ marginRight: '5px' }}
                  />
                </Space>
                <span>※최대 조회기간은 6개월 입니다.</span>
              </div>
              <div className='search-menu'>공개여부</div>
              <div className='menu-item' style={{ textAlign: 'left' , padding:'15px' }}>
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>전체</Radio>
                  <Radio value={2}>공개</Radio>
                  <Radio value={3}>비공개</Radio>
                </Radio.Group>
              </div>
              <div className='search-menu'>검색어</div>
              <div className='menu-item' style={{ textAlign: 'left', padding:'15px' }}>
                <Space direction="vertical">
                  <Search
                    placeholder="검색어를 입력하세요."
                    onSearch={handleSearch}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        handleClearSearch();
                      }
                    }}
                    style={{
                      width: 200,
                    }}
                  /></Space>
              </div>
            </div>
          </TitleItem>
        </div>
        <div>
          <div style={{ display: 'grid', placeItems: 'end', marginBottom: '10px' }}>
            <Button
              type="primary"
              href='techBoard/write'
              style={{ marginTop: '15px' }}
            >
              글쓰기
            </Button>
          </div>
          <div style={{ fontFamily: '"Noto Sans KR", sans-serif' }}>
            <Table dataSource={filteredDataSource} columns={columns} className='tables' style={{ fontSize: '13px' }} />
          </div>
        </div>
      </div>
    </Content>
  );
};

const TechTrend = () => {
  return (
    <TechIndex>
      <TechTrendMain />
    </TechIndex>
  );
};

export default TechTrend;
